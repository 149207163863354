<template>
  <div class="Script">
    <div class="Script_left">
      <div>
        <span class="el-icon-caret-right"></span>
        <span class="span1">全部（36）</span>
        <span class="el-icon-plus"></span>
        <span class="span2" @click="NewlyBuild">新建</span>
        <!-- <span class="span3">
               <span class="el-icon-arrow-right"></span>
        </span> -->
      </div>
      <div>
        <el-tree @node-click="handleNodeClick" :data="data" :props="defaultProps" default-expand-all highlight-current
                 node-key="Id" ref="treeList">
          <div class="custom-tree-node" slot-scope="{ node,data }">
            <span v-show="data.Id!=Id">{{ data.Name }}</span>
            <span v-show="data.Id==Id" class="node_span">
                                <el-input @blur="onblur(data)" v-model="Name" placeholder="请输入内容"></el-input>
                            </span>
            <el-popover
                trigger="hover"
                placement="bottom">
              <p style="text-align: center; margin-bottom: 10px;" @click="AddGrouping(node,data)">新增下级分组</p>
              <p style="text-align: center; margin-bottom: 10px;" @click="EditGrouping(node,data)">编辑</p>
              <p style="text-align: center;" @click="DeleteGrouping(node,data)">删除</p>
              <span slot="reference" class="el-icon-s-tools"></span>
            </el-popover>
          </div>
        </el-tree>
      </div>
    </div>
    <div class="Script_right">
      <div class="Script_right_1">
        <div>
          <span><el-input v-model="input" placeholder="请输入内容"></el-input></span>
          <span><el-button type="primary" icon="el-icon-search">查询</el-button></span>
        </div>
        <div>
          <el-button type="primary" icon="el-icon-plus" @click="AddProblem">添加</el-button>
        </div>
      </div>
      <div style="margin-bottom: 20px;">当前知识库共包含36问题</div>
      <div class="Script_right_2">
        <el-table
            border
            :data="tableData"
            :header-cell-style="{background: 'rgb(240, 247, 253)'}"
            style="width: 100%">
          <el-table-column
              align="center"
              prop="Text"
              label="标准问题 "
              width="180">
          </el-table-column>
          <el-table-column
              align="center"
              prop="KeyWordCount"
              label="关键词"
              width="180">
          </el-table-column>
          <el-table-column
              align="center"
              prop="UpdateTime"
              label="更新时间">
          </el-table-column>
          <el-table-column
              align="center"
              fixed="right"
              label="操作"
              width="200">
            <template slot-scope="scope">
              <el-link @click="handleClick(scope.row)">
                <i class="el-icon-edit"></i>
                编辑
              </el-link>
              <el-link>
                <i class="el-icon-delete"></i>
                删除
              </el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
        title="新增目录"
        :visible.sync="dialogVisible1"
        width="30%">
      <div>
        <el-input v-model="parameter.Name" placeholder="请输入内容"></el-input>
      </div>
      <div slot="footer" class="btn">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="determine1">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
        title="新增下级目录"
        :visible.sync="dialogVisible2"
        width="30%">
      <div>
        <el-input v-model="parameter.Name" placeholder="请输入内容"></el-input>
      </div>
      <div slot="footer" class="btn">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="determine2">确 定</el-button>
      </div>
    </el-dialog>
    <div class="drawer_box">
      <el-drawer
          title="我是标题"
          :visible.sync="drawer">
        <div>
          <el-form ref="form" label-width="80px">
            <el-form-item label="类型:">
              <span>一般问题</span>
            </el-form-item>
            <el-form-item label="选择分组:">
              <el-cascader
                  v-model="GroupId"
                  :options="options"
                  :props="defaultProps"
                  :show-all-levels="false"
                  @change="change"
                  checkStrictly
              >
              </el-cascader>
            </el-form-item>
            <el-form-item label="标准问题:">
              <el-input v-model="problemparameter.Text" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <div class="keyword">
            <el-tag
                v-show="KeyWords.length!=0"
                :key="index"
                v-for="(item,index) in KeyWords"
                closable
                :disable-transitions="false"
                @close="handleClose(item)">
              {{ item.Text }}
            </el-tag>
            <el-input
                class="input-new-tag"
                v-if="inputVisible"
                v-model="inputValue"
                ref="saveTagInput"
                size="small"
                @keyup.enter.native="handleInputConfirm"
                @blur="handleInputConfirm"
            >
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">+添加关键词</el-button>

          </div>
          <div v-for="(item,index) in Answers" :key="index" class="problem_box">
            <problem :item="item"></problem>
            <div @click="clickdelete(item)" class="el-icon-delete"></div>
          </div>
          <div class="link_btn">
            <el-link type="primary" @click="fillin"><span class="el-icon-plus"></span>添加新的回答</el-link>
          </div>
          <div class="btn">
            <el-button @click="drawer = false">取 消</el-button>
            <el-button type="primary" @click="Adddrawer">确 定</el-button>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import RobotCenter from "../../api/RobotCenter"
import problem from "./problem.vue"

export default {
  data() {
    return {
      value: [],
      textarea: '',
      visible: false,
      form: {
        region: ''
      },
      tableData: [],
      defaultProps: {
        children: 'Children',
        label: 'Name',
        value: "Id",
      },
      input: '',
      dialogVisible1: false,
      dialogVisible2: false,
      drawer: false,
      Name: '',
      data: [],
      Id: "",
      GroupId: [],
      parameter: {
        RobotId: this.$route.query.Id,
        GroupId: "",
        Name: '',
        Id: null
      },
      options: [],
      Answers: [
        {
          id: this.newGuid(),
          Text: '',
          Index: 1,
          NextAction: '01',
        }
      ],
      problem: [],
      index: 1,
      KeyWords: [],
      inputVisible: false,
      inputValue: '',
      problemparameter: {
        RobotId: this.$route.query.Id,
        GroupId: "",
        Text: '',
        Answers: [],
        KeyWords: []
      },
    }
  },
  components: {
    problem,
  },
  created() {
    this.GroupGetTree()
  },
  mounted() {
  },
  methods: {
    handleNodeClick(data) {
      this.QuestionGetPageList(data.Id)
      console.log(data);
    },
    clickdelete(item) {
      this.Answers = this.Answers.filter(row => {
        if (item.id != row.id) {
          return row
        }
      })
    },
    newGuid() {
      var guid = "";
      for (var i = 1; i <= 32; i++) {
        var n = Math.floor(Math.random() * 16.0).toString(16);
        guid += n;
        if ((i == 8) || (i == 12) || (i == 16) || (i == 20))
          guid += "-";
      }
      return guid;
    },
    handleClose(row) {
      this.KeyWords = this.KeyWords.filter(item => {
        if (row.id != item.id) {
          return item
        }
        console.log(item);
      })
      console.log(this.KeyWords);
    },
    handleInputConfirm() {
      let obj = {
        id: this.newGuid(),
        //   RobotId:this.$route.query.Id,
        //   GroupId:this.problemparameter.GroupId,
        Text: this.inputValue,
      }
      this.KeyWords.push(obj)
      this.inputVisible = false;
      this.inputValue = '';
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    fillin() {
      this.index++
      const obj = {
        id: this.newGuid(),
        Text: '',
        Index: this.index,
        NextAction: '01',
      }
      this.Answers.push(obj)
      console.log(this.Answers);
    },
    change() {
      this.problemparameter.GroupId = this.GroupId[this.GroupId.length - 1]
    },
    Adddrawer() {
      this.problemparameter.Answers = this.Answers
      this.problemparameter.KeyWords = this.KeyWords
      console.log(this.problemparameter);
      RobotCenter.KnowledgeBaseQuestionAddData(this.problemparameter).then(res => {
        if (res.data.Status == 1) {
          this.tableData.push(res.data.Data)
          this.drawer = false
        } else {
          alert(res.data.message)
        }
        console.log(res);
      })
    },
    //打开添加问题弹框
    AddProblem() {
      this.drawer = true
    },
    QuestionGetPageList(GroupId) {
      let parameter = {
        Key: '',
        page: 1,
        rows: 10,
        RobotId: this.$route.query.Id,
        GroupId: GroupId,
      }
      RobotCenter.QuestionGetPageListData(parameter).then(res => {
        if (res.data.Status == 1) {
          this.tableData = res.data.Data.rows
        }
        console.log(res);
      })
    },
    //删除分组
    DeleteGrouping(node, data) {
      console.log(node);
      let parameter = []
      parameter.push(data.Id)
      this.$confirm('此操作将永久删除该目录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        RobotCenter.KnowledgeBaseGroupDeleteData(parameter).then(res => {
          if (res.data.Status == 1) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }
          console.log(res);
        })
      }).catch(() => {
      });
    },
    //更新分组
    onblur(data) {
      this.Id = ""
      this.parameter.ParentId = data.ParentId
      this.parameter.Name = this.Name
      this.parameter.Id = data.Id
      RobotCenter.KnowledgeBaseGroupAddData(this.parameter).then(res => {
        console.log(res, "修改");
      })
    },
    EditGrouping(node, data) {
      console.log(node);
      console.log(data);
      this.Id = data.Id
      this.visible = false
    },
    //打开分租弹框
    NewlyBuild() {
      this.dialogVisible1 = true
    },
    determine1() {
      this.parameter.ParentId = 0
      RobotCenter.KnowledgeBaseGroupAddData(this.parameter).then(res => {
        if (res.data.Status == 1) {
          this.data.push(res.data.Data)
          this.dialogVisible1 = false
        } else {
          alert(res.data.Message)
        }
      })
    },
    determine2() {
      RobotCenter.KnowledgeBaseGroupAddData(this.parameter).then(res => {
        console.log(res);
        if (res.data.Status == 1) {
          this.data = this.ArrList(this.data, res.data.Data)
          console.log(this.data);
          this.dialogVisible2 = false
        } else {
          alert(res.data.Message)
        }
      })
    },
    recursion(arr) {
      return arr.map(item => {
        if (item.Children.length != 0) {
          this.recursion(item.Children)
        } else {
          delete item.Children
        }
        return item
      })
    },
    ArrList(arr, data) {
      return arr.map(item => {
        if (item.Children == null) {
          item.Children = []
        }
        if (item.Id == this.parameter.ParentId) {
          item.Children.push(data)
        } else {
          this.ArrList(item.Children, data)
        }
        return item
      })
    },
    //知识库分组树形结构
    GroupGetTree() {
      const parameter = {
        RobotId: this.$route.query.Id,
      }
      RobotCenter.GroupGetTreeData(parameter).then(res => {
        if (res.data.Status == 1) {
          this.data = JSON.parse(JSON.stringify(res.data.Data))
          this.options = this.recursion(res.data.Data)
          this.QuestionGetPageList(this.data[0].Id)
          this.$nextTick(() => {
            this.$refs['treeList'].setCurrentKey(1000);  //"treeList"是你自己在树形控件上设置的 ref="treeList" 的名称
          })
        }
        console.log(res);
      })
    },
    AddGrouping(node, data) {
      console.log(node);
      console.log(data);
      this.parameter.ParentId = data.Id
      console.log(this.parameter.ParentId);
      this.dialogVisible2 = true
    },
    //编辑问题
    handleClick(row) {
      console.log(row);
    }
  }
}
</script>
<style lang="scss" scoped>
.problem_box {
  position: relative;

  .el-icon-delete {
    font-size: 24px;
    position: absolute;
    right: 20px;
    top: 18px;
    cursor: pointer;
  }
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.keyword {
  margin-bottom: 20px;
}

.link_btn {
  margin-bottom: 10px;
}

.button-new-tag {
  // margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.drawer_box {
  ::v-deep .el-drawer__body {
    padding: 0px 30px 10px 30px;
    box-sizing: border-box;
  }
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.node_span {
  ::v-deep .el-input__inner {
    height: 30px;
  }
}

span {
  display: inline-block;
}

.Script {
  width: 100%;
  margin-top: 30px;
  display: grid;
  grid-template-columns: 240px 1fr;
  column-gap: 20px;

  .Script_left {
    width: 240px;
    height: 700px;
    border: 1px solid #EBEBEB;
    float: left;
    padding: 20px;
    box-sizing: border-box;

    .el-icon-plus {
      color: #3388FF;
    }

    .span2 {
      color: #3388FF;
      margin-right: 20px;
    }

    .span3 {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #3388FF;
      text-align: center;
      line-height: 20px;
      color: #3388FF;
    }
  }

  .Script_right {
    float: left;
    width: calc(100% - 20px);
    height: 700px;
    box-sizing: border-box;
    padding-right: 20px;
    //margin-left: 20px;

    .Script_right_1 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }
}
</style>
