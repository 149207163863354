<template>
    <div>
               <div class="drawer_form">
                    <el-form ref="form"  label-width="80px">
                            <el-form-item label="回答">
                            </el-form-item>
                            <el-form-item label="回答问题:">
                                    <el-input
                                    type="textarea"
                                    placeholder="请输入内容"
                                    v-model="item.Text">
                                    </el-input>
                            </el-form-item>
                            <el-form-item label="回答后:">
                            <el-select v-model="item.NextAction" placeholder="请选择活动区域">
                                <el-option :label="row.Name" :value="row.Code" v-for="(row,index) in problem" :key="index"></el-option>
                                </el-select>
                            </el-form-item>
                     </el-form>
                 </div>
    </div>
</template>
<script>
import PatientCenter from "../../api/PatientCenter"
export default {
    data(){
        return{
            problem:[]
        }
    },
    props:['item'],
    created(){
       this.type()
    },
    methods:{
        type(){
         const Dialect="HDH"
         PatientCenter.BaseDataBigData(Dialect).then(res=>{
                      console.log(res);
                   if(res.data.Status==1){
                      this.problem=res.data.Data
                   }else{
                        alert(res.data.Message)
                    }
         })
        }
    }
}
</script>
<style lang="scss" scoped>
.drawer_form{
    background: #F7F7F7;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 20px;
}
</style>