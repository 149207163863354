import axios from "./Interceptor.js"
// import qs from "qs"
let address = window.global_config.BASE_URL2

let ProcessList = {
    GetListData: (info) => { // 流程列表
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/Flow/GetList`,
            data: info,
        })
    },
    AddFlowData: (info) => { // 添加流程
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/Flow/Add`,
            data: info,
        })
    },
    EditFlowData: (info) => { // 修改流程
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/Flow/Edit`,
            data: info,
        })
    },
    DeleteFlowData: (info) => { // 删除流程
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/Flow/Delete`,
            data: info,
        })
    },
    AddFloSaveData: (info) => { // 保存话术流程
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/Flow/Node/Save`,
            data: info,
        })
    },
    GetNodesData: (info) => {//查出流程图
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/Flow/GetNodes`,
            data: info,
        })
    },
    BranchGetListData: (info) => {//分支列表
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/Branch/GetList`,
            data: info,
        })
    },
}

export default ProcessList
