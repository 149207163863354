// 首页项目管理的接口
import axios from "./Interceptor.js"
import qs from "qs"
let address = window.global_config.BASE_URL2
let ProjectManagement = {
    // 分组列表
    ProjectGroupGetPageListData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Group/GetPageList`,
            data: info,
        })
    },
    // 新增分组
    AddProjectGroupData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Group/Add`,
            data: info,
        })
    },
    // 新增入组模型
    AddProjectGroupingGroupData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Group/Grouping`,
            data: info,
        })
    },
    // 更新分组
    EditProjectGroupData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Group/Edit`,
            data: info,
        })
    },
    // 项目列表分页
    GetListData: (info) => {
        return axios({
            method: "post",
            // url: `${address}Project/GetPageList`,
            url: `${address}Project/GetTree`,
            data: info,
        })
    },
    // 新增项目
    AddProjectData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Add`,
            data: info,
        })
    },
    // 编辑项目
    EditProjectData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Edit`,
            data: info,
        })
    },
    //删除项目
    DeleteProjectData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Delete`,
            data: info,
        })
    },
    //项目信息汇总
    QueryProjectSummaryData: () => {
        return axios({
            method: "post",
            url: `${address}Project/GetSummary`,
        })
    },
    //查询项目信息
    QueryProjectData: (id) => {
        return axios({
            method: "get",
            url: `${address}Project/Get`,
            params: { id: id },
        })
    },
    //单位列表
    GetListCompanyData: (info) => {
        return axios({
            method: "post",
            url: `${address}Unit/GetList`,
            data: info,
        })
    },
    //单位列表
    GetPageListCompanyData: (info) => {
        return axios({
            method: "post",
            url: `${address}Unit/GetPageList`,
            data: info,
        })
    },
    //参与人员列表
    GetPageListData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/User/GetPageList`,
            data: info,
        })
    },
    //参与人员新增成员
    AddUserData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/User/Add`,
            data: info,
        })
    },
    //参与人员编辑成员
    EditUserData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/User/Edit`,
            data: info,
        })
    },
    //参与人员删除成员
    DeleteUserData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/User/Delete`,
            data: info,
        })
    },
    //角色列表
    RoleListData: (info) => {
        console.log(info, "info--info--info");
        return axios({
            method: "post",
            // url:`${address}Authorize/GetRoles`,
            url: `${address}Role/GetList`,
            data: info
        })
    },
    //患者列表
    QGetPageListData: (info) => {
        return axios({
            method: "post",
            url: `${address}PatientCenter/GetPageList`,
            data: info,
        })
    },
    //项目患者列表
    ProjectGetPageListData: (info) => {
        return axios({
            method: "post",
            url: `${address}PatientCenter/GetPageList`,
            data: info,
        })
    },
    //新增项目患者
    AddProjectPatientData: (info) => {
        return axios({
            method: "post",
            // url: `${address}PatientCenter/Add`,
            url: `${address}PatientCenter/Adds`,
            data: info,
        })
    },
    //修改项目患者
    EditProjectPatientData: (info) => {
        return axios({
            method: "post",
            url: `${address}PatientCenter/Edit`,
            data: info,
        })
    },
    //删除项目患者
    DeleteProjectPatientData: (info) => {
        return axios({
            method: "post",
            url: `${address}PatientCenter/Delete`,
            data: info,
        })
    },
    //患者画像信息
    PatientProfilingData: (info) => {
        return axios({
            method: "get",
            url: `${address}PatientCenter/Profiling`,
            params: info,
        })
    },
    //新增患者档案
    AddArchiveData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Patient/Archive/Add`,
            data: info,
        })
    },
    //编辑患者档案
    EditArchiveData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Patient/Archive/Edit`,
            data: info,
        })
    },
    //删除患者档案
    DeleteArchiveData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Patient/Archive/Delete`,
            data: info,
        })
    },
    //删除患者档案附件
    DeleteAttArchiveData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Patient/Archive/Att/Delete`,
            data: info,
        })
    },
    //参与单位列表
    CompanyListData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Unit/GetPageList`,
            data: info,
        })
    },
    //添加参与单位
    AddCompanyData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Unit/Add`,
            data: info,
        })
    },
    //修改参与单位
    EditCompanyData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Unit/Edit`,
            data: info,
        })
    },
    //删除参与单位
    DeleteCompanyData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Unit/Delete`,
            data: info,
        })
    },
    //无分页参与单位列表
    NothingCompanyListData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Unit/GetList`,
            data: info,
        })
    },
    //添加规则
    AddRuleData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Questionnaire/Rule/Add`,
            data: info,
        })
    },
    //修改规则
    EditRuleData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Questionnaire/Rule/Edit`,
            data: info,
        })
    },
    //题目列表
    ListOfTopicsData: (info) => {
        return axios({
            method: "get",
            url: `${address}Project/Questionnaire/Rule/GetTopicList`,
            params: info,
        })
    },
    //无分页规则列表
    NothingRuleListData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Questionnaire/Rule/GetList`,
            data: info,
        })
    },
    //有分页规则列表
    HaveRuleListData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Questionnaire/Rule/GetPageList`,
            data: info,
        })
    },
    //规则停止
    StopRuleData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Questionnaire/Rule/Stop`,
            data: info,
        })
    },
    //规则开始
    StartRuleData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Questionnaire/Rule/Start`,
            data: info,
        })
    },
    //规则删除
    DeleteRuleData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/Questionnaire/Rule/Delete`,
            data: info,
        })
    },
    //项目成员列表
    ProjectMemberData: (info) => {
        return axios({
            method: "post",
            url: `${address}User/GetNoOmPageList`,
            data: info,
        })
    },
    //确定成员批量添加
    DetermineMemberData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/User/BatchAdd`,
            data: info,
        })
    },
    //机器人列表
    GetRobotListData: (ProjectId) => {
        return axios({
            method: "get",
            url: `${address}RobotCenter/GetRobotList`,
            params: { projectId: ProjectId },
        })
    },
    //单个拨打机器人
    ExecuteOnceData: (info) => {
        return axios({
            method: "post",
            url: `${address}FollowUp/Execute/Once`,
            data: info,
        })
    },
    //导入
    PatientExportData: (info) => {
        return axios({
            method: "post",
            url: `${address}PatientCenter/Import`,
            data: info,
        })
    },
    //下载模板
    TemplateDownloadData: () => {
        return axios({
            method: "get",
            url: `${address}PatientCenter/TemplateDownload`,
        })
    },
    //获取牵头单位Id
    GetLeadingUnitId: () => {
        return axios({
            method: "post",
            url: `${address}Organization/GetOrganizationLeader`,
        })
    },
}

export default ProjectManagement
