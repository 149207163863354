<template>
  <div>
    <div class="Speech">
      <!-- <input type="text" placeholder="按回车键提示" @keyup.delete="tip()"> -->
      <div class="Speech_left">
        <div class="left_1" @click="FlowDialogfn">
          <div class="el-icon-plus"></div>
          <div>添加</div>
        </div>
        <div class="left_2">
          <el-input placeholder="请输入内容" v-model="input3" class="input-with-select">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
        <div class="left_3">
          <div v-for="(item, index) in FlowList" :key="index" class="left_list"
            :class="{ BackgroundClass: item.Id == FlowId }" @click="FlowListFn(item)">
            <div class="left_item1">
              <div>
                <div class="el-icon-s-grid"></div>
              </div>
              <div>
                <div class="el-icon-edit-outline" @click="ModifyFlowFn(item)"></div>
                <div class="el-icon-delete" @click="DeleteFlow(item)"></div>
              </div>
            </div>
            <div class="left_item2">{{ item.Name }}</div>
          </div>
        </div>
      </div>

      <div class="Speech_right">
        <div class="top_nav">
          <!-- 添加节点 -->
          <div>
            <div v-if="FlowList.length > 0" class="top_nav1">
              <div class="top" draggable="true" @dragend="handleDragEnd($event, item)" v-for="(item, index) in TopNav"
                :key="index">{{ item.name }}</div>
            </div>
          </div>
          <div class="top_nav2">
            <el-button type="primary" @click="Returntothepreviouslevel">返回上一级</el-button>
          </div>
        </div>
        <!-- @click="handleBlank" -->
        <div class="topology" ref="topology" @mousedown.stop="sliderDown" @click="handleBlank" id="box" @scroll="scrool">
          <div class="nav" id="nav_box" :style="{ '--LargeNum': LargeNum }">
            <div class="nav_item name1" @keyup.enter="NodeDelete()" @dblclick="SetUp(item)" @click="BorderFn(item)"
              :class="[
                { BorderClass: item.Id == IsId },
                { nodebackground: item.Id == Bid },
              ]" ref="deviationCharts" :id="item.Id" draggable="true" @dragleave="dragleave($event)"
              @dragover="dragover" @dragenter="dragenter($event, item)" @drop="drop($event, item)"
              @dragend.stop="boxdragend($event, item)" @dragstart="boxdragstart($event, item)"
              @contextmenu.prevent.stop="contextmenu($event, item)" v-for="(item, index) in nav" :key="index" :style="{
                position: 'absolute',
                left: item.Left + 'px',
                top: item.Top + 'px',
                width: item.Width + 'px',
              }">
              <div class="title">
                <div>
                  <span class="el-icon-s-help" v-if="item.Type == '00'"></span>
                  <span class="el-icon-share" v-if="item.Type == '01'"></span>
                  {{ item.Name }}
                </div>
                <span class="el-icon-microphone"></span>
              </div>
              <div class="name" v-for="(m, n) in item.SpeechConfigs" :key="n">{{ m.Text }}</div>
              <div class="item_flex" v-if="item.Type == '00'">
                <div v-show="row.Checked" :id="row.Id" @dragend.stop="dragend" @dragstart="dragstart($event, row, item)"
                  class="item_1" v-for="(row, i) in item.BranchConfigs" :key="i" @drag="Drawaline($event, i)"
                  draggable="true" :class="[
                    { sure: row.Type == '00' },
                    { negative: row.Type == '01' },
                    { refuse: row.Type == '02' },
                    { default: row.Type == '03' },
                    { neutral: row.Type == '04' },
                  ]">{{ row.Name }}</div>
              </div>
              <div class="item_nextstep" v-if="item.Type == '01'">
                下一步:&nbsp;&nbsp;{{
                  item.JumpConfig.NextAction == "00"
                  ? "挂机"
                  : item.JumpConfig.NextAction == "01"
                    ? "执行下一步"
                    : item.JumpConfig.NextAction == "02"
                      ? item.JumpConfig.NextFlowName
                      : ""
                }}
              </div>
            </div>

            <div class="contentctx" :style="{ top: topSound + 'px', left: leftSound + 'px' }">
              <!-- <div class="copyValue">复制</div> -->
              <div class="soundRecording" @click="soundRecording">录音</div>
            </div>
            <svg class="topology_svg">
              <g v-for="(item, index) in list" :key="index" @mouseover="FnMouseover(item)" @mouseout="FnMouseout">
                <path :d="updated(
                  item.OriginX1,
                  item.OriginY1,
                  item.OriginX2,
                  item.OriginY2,
                  item.ArrowX1,
                  item.ArrowY1,
                  item.ArrowX2,
                  item.ArrowY2
                )
                  " :style="{
    stroke: item.IsRbg,
    'stroke-width': LineId == item.Id ? '3px' : '1px',
    'pointer-events': 'all',
  }" fill="none" />
              </g>
              <!-- @mousedown="takePhoto($event, item)" -->
              <g v-for="(item, index) in list" :key="index + 'q'" style="cursor: pointer">
                <path style="pointer-events: all; cursor: crosshair" :fill="item.IsRbg" stroke="none" datatype="end"
                  :d="arrow(item.ArrowX1, item.ArrowY1)" />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div class="function_bottom">
      <div class="LargeNumFn">
        <div class="el-icon-circle-plus" @click="LargeNumFn(1)"></div>
        <div class="percentage">{{ percentage + "%" }}</div>
        <div class="el-icon-remove" @click="LargeNumFn(2)"></div>
      </div>
      <div class="baocun">
        <el-button type="primary" @click="preservation">保存</el-button>
      </div>
    </div>

    <el-drawer :show-close="false" :before-close="beforeClose" @mousedown.native="drawerMousedown" @close="quedingcancel"
      size="25%" title="编辑普通节点" :visible.sync="drawer" destroy-on-close direction="rtl">
      <div class="form_box">
        <el-form label-width="80px">
          <el-form-item label="问卷题目:" v-if="form.Type == '00' && FieldList.length > 0">
            <el-select v-model="TopicId" placeholder="请选择" value-key="id" clearable @change="ChangeTopic">
              <el-option v-for="(item, index) in FieldList" :key="index" :label="item.name" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="节点名称">
            <el-input v-model="form.Name" @input="ChangeTitle"></el-input>
          </el-form-item>
          <el-form-item label="切换规则">
            <div class="form_ToggleRules">
              <div @click="ToggleRulesFn(item.id)" :class="{ ClassBorder: index == isindex }"
                v-for="(item, index) in ToggleRules" :key="index" class="ToggleRules">{{ item.name }}</div>
            </div>
          </el-form-item>
          <el-form-item label="节点话术">
            <div v-for="(item, index) in SpeechConfigs" :key="index">
              <el-input type="textarea" @change="ChangeTextarea($event, item)" v-model="item.Text"></el-input>
            </div>
          </el-form-item>
          <div v-show="form.Type == '00'">
            <el-form-item label="回答分支">
              <div class="Answerbranch">
                <div v-for="(item, index) in NodeList" :key="index" class="Answerbranch_item">
                  <div>
                    <el-checkbox v-model="item.Checked" @change="BranchFn($event, item)">{{ item.Name }}</el-checkbox>
                    <span class="el-icon-edit" @click="AnswerbranchFn(item)"></span>
                  </div>
                </div>
              </div>
              <div class="custom">
                <div class="custom_add">
                  <div>自定义回答分支</div>
                  <div class="add_branch" @click="CustomAnswerbranchFn">+增加分支</div>
                </div>
                <div>
                  <div v-for="(item, index) in obj.Branchs" :key="index" class="Answerbranch_custom">
                    <div class="Answerbranch_item">
                      <el-checkbox @change="BranchFn($event, item)" v-model="item.Checked">{{ item.Name }}</el-checkbox>
                      <span class="el-icon-edit" @click="Editcustom(item)"></span>
                      <el-popconfirm cancel-button-type="Primary" title="确定删除吗？" @confirm="deletecustom(item)">
                        <span slot="reference" class="el-icon-delete"></span>
                      </el-popconfirm>
                    </div>
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>
          <div v-show="form.Type == '01'">
            <el-form-item label="下一步">
              <el-select v-model="NextAction" placeholder="请选择" @change="NextActionFn">
                <el-option v-for="(item, index) in NextStepList" :key="index" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择流程" v-show="NextAction == '02'">
              <el-select v-model="NextFlowId" placeholder="请选择" @change="NextFlowIdFn" value-key="Id">
                <el-option v-for="(item, index) in FlowList" :key="index" :label="item.Name" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="form_btn">
        <el-button @click="quedingcancel">取消</el-button>
        <el-button type="primary" @click="queding">确定</el-button>
      </div>
    </el-drawer>
    <el-dialog :visible.sync="AddBranchDialog" width="30%">
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">{{ title }}</span>
      </div>
      <div>
        <el-form label-width="90px">
          <el-form-item label="分支名称" required>
            <el-input v-model="BranchObj.Name" :disabled="BranchDisable"></el-input>
          </el-form-item>
          <el-form-item label="分支属性" required>
            <el-select v-model="BranchObj.Type" placeholder="请选择" :disabled="BranchDisable">
              <el-option v-for="(item, index) in TypeList" :key="index" :label="item.Name" :value="item.Type"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关键词">
            <!-- <el-input v-model="BranchObj.KeyWord"></el-input> -->
            <el-tag :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false"
              @close="handleClose(tag)">{{ tag }}</el-tag>
            <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small"
              @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm"></el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加关键词</el-button>
          </el-form-item>
          <el-form-item label="答法" required>
            <el-input v-model="BranchObj.Answer" type="textarea" :disabled="BranchDisable"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="btn">
        <el-button @click="AddBranchDialog = false">取 消</el-button>
        <el-button type="primary" @click="AddBranch">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="FlowDialog" width="30%">
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">新增流程</span>
      </div>
      <div>
        <el-form label-width="80px">
          <el-form-item label="流程名称">
            <el-input v-model="FlowName"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="btn">
        <el-button @click="FlowDialog = false">取 消</el-button>
        <el-button type="primary" @click="AddFlow">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="ModifyFlowDialog" width="30%">
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">修改流程</span>
      </div>
      <div>
        <el-form label-width="80px">
          <el-form-item label="流程名称">
            <el-input v-model="ModifyFlowName"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="btn">
        <el-button @click="ModifyFlowDialog = false">取 消</el-button>
        <el-button type="primary" @click="ModifyAddFlow">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="CustomAddBranchDialog" width="30%">
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">编辑分支</span>
      </div>
      <div>
        <el-form label-width="90px">
          <el-form-item label="分支名称" required>
            <el-input v-model="BranchObj.Name" disabled></el-input>
          </el-form-item>
          <el-form-item label="分支属性" required>
            <el-select v-model="BranchObj.Type" placeholder="请选择">
              <el-option v-for="(item, index) in TypeList" :key="index" :label="item.Name" :value="item.Type"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关键词">
            <!-- <el-input v-model="BranchObj.KeyWord"></el-input> -->
            <el-tag :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false"
              @close="handleClose(tag)">{{ tag }}</el-tag>
            <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small"
              @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm"></el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加关键词</el-button>
          </el-form-item>
          <el-form-item label="答法" required>
            <el-input v-model="BranchObj.Answer" type="textarea"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="btn">
        <el-button @click="CustomAddBranchDialog = false">取 消</el-button>
        <el-button type="primary" @click="AddCustomBranch">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="centerDialogVisible" width="30%" center :before-close="handleCloseAudio"
      @open="AudioOpened">
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">话术录音</span>
      </div>
      <div style="text-align: center; margin-bottom: 20px; font-size: 18px">录音</div>
      <div class="iconAudio">
        <el-tooltip :content="isPlay ? '播放录音' : '暂停播放'" placement="top">
          <img v-if="isPlay" src="../../assets/image/play.png" @click="handlePlay" style="margin-top: 3px" />
          <img v-else src="../../assets/image/suspend.png" @click="handlePausePlay" style="margin-top: 3px" />
        </el-tooltip>

        <div style="
            flex: 1;
            border: 1px solid #eee;
            padding: 0 5px;
            height: 30px;
            line-height: 30px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          ">
          <span v-if="navItemValue.SpeechConfigs">
            {{
              navItemValue.SpeechConfigs[0].Text
            }}
          </span>
        </div>
        <el-upload class="avatar-uploader" :action="uploadUrl" :data="{
          RobotId: $route.query.Id,
          FlowId: FlowId,
          NodeId: navItemValue.Id,
          SpeechId: navItemValue.SpeechConfigs
            ? navItemValue.SpeechConfigs[0].Id
            : '',
        }" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
          <el-tooltip content="上传录音" placement="top">
            <img src="../../assets/image/upload.png" style="margin-top: 3px" />
          </el-tooltip>
        </el-upload>
        <el-tooltip :content="isStart ? '开始录音' : '暂停录音'" placement="top">
          <img v-if="isStart" src="../../assets/image/Microphone.png" @click="handleStart" />
          <img v-else src="../../assets/image/FredMicrophone.png" @click="handleStop" />
        </el-tooltip>
        <el-tooltip :content="!isDownload ? '下载录音' : '暂无音频'" placement="top">
          <img v-if="!isDownload" src="../../assets/image/download.png" @click="downloadAudio" />
          <img v-else src="../../assets/image/Fendownload.png" />
        </el-tooltip>
        <el-tooltip :content="!isDelete ? '删除录音' : '暂无音频'" placement="top">
          <img v-if="!isDelete" src="../../assets/image/delete.png" @click="deleteAudio" />
          <img v-else src="../../assets/image/Fendelete.png" />
        </el-tooltip>
      </div>
      <div style="text-align: center; color: rgb(166, 166, 166)">上传录音仅支持WAV格式的音频文件</div>
      <!-- <h4>录音时长：{{ recorder && recorder.duration.toFixed(4) }}</h4>
      <h4>播放时间：{{ playTime.toFixed(4) }}</h4>-->
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" type="primary" @click="UploadAudio(item)" :disabled="isAbled">保存录音</el-button>
      </span>
      <audio :src="navItemValue.SpeechConfigs
        ? navItemValue.SpeechConfigs[0].VoiceFile
        : ''
        " id="audioPlay"></audio>
    </el-dialog>
  </div>
</template>

<script>
import { json } from "body-parser";
import Recorder from "js-audio-recorder";
import ProcessList from "../../api/ProcessList";
import ProjectManagement from "../../api/ProjectManagement";
import RobotCenter from "../../api/RobotCenter";
export default {
  data() {
    return {
      className: "", // 打开编辑节点模态框鼠标按下时DOM的类名
      //用来保存xy坐标
      leftSound: 0,
      topSound: 0,
      //存放右键navItem值
      navItemValue: {},
      //录音dialog
      centerDialogVisible: false,
      //控制play播放
      isPlay: true,
      //控制start录制
      isStart: true,
      //控制下载
      isDownload: true,
      //控制删除
      isDelete: true,
      //存放wav bobl文件
      recorder: null,
      //存放播放到的时间
      playTime: 0,
      //控制播放的定时器
      timer: "",
      //isabled
      isAbled: true,
      //uploadUrl
      uploadUrl:
        window.global_config.BASE_URL2 + "RobotCenter/Robot/UploadVoiceFile",

      CustomAddBranchDialog: false, //自定义分支编辑弹框
      title: "编辑分支",
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",

      ModifyFlowDialog: false,
      ModifyFlowName: "",
      isindex: "00",
      ToggleRules: [
        { id: "00", name: "顺序" },
        { id: "01", name: "系统时间" },
      ],
      CustomNodeList: [],
      BranchObj: {
        RobotId: this.$route.query.Id,
        Name: "",
        Type: "",
        KeyWord: "",
        Answer: "",
        NextNodeId: "",
        NodeId: "",
        Id: "",
        Checked: false,
      },
      TypeList: [
        { Name: "肯定", Type: "00" },
        { Name: "否定", Type: "01" },
        { Name: "拒绝", Type: "02" },
        { Name: "默认", Type: "03" },
        { Name: "中性", Type: "04" },
      ],
      NextAction: "01",
      NextFlowId: {
        Id: "",
      },
      NextStepList: [
        {
          value: "00",
          label: "挂机",
        },
        {
          value: "01",
          label: "下一主动流程",
        },
        {
          value: "02",
          label: "指定主动流程",
        },
      ],
      IsId: "",
      percentage: 100,
      value: 0,
      input3: "",
      FlowName: "",
      FlowDialog: false,
      FlowList: [],

      drawer: false,
      AddBranchDialog: false,
      flag: false,
      nodeName: "", //分支名称
      nav: [],
      list: [],
      Bid: "", //节点的背景id
      id: "", //节点的id
      Id: "", //回答分支的id
      x1: 0, //存拖拽开始的原点x
      y1: 0, //存拖拽开始的原点y
      boxWidth: 260, //定义节点盒子的宽带
      boxHeight: 90, //定义节点盒子的高度

      offsetX: 0, //存节点盒子开始拖拽时候鼠标点击在节点位置x
      offsetY: 0, //存节点盒子开始拖拽时候鼠标点击在节点位置y
      form: {}, //节点所有信息
      checked: false,
      SpeechConfigs: [], //节点内容
      NodeList: [
        {
          Name: "肯定",
          NodeId: "",
          Id: "",
          Type: "00",
          Checked: true,
          NextNodeId: "",
          KeyWord: "",
          Answer: "好",
        },
        {
          Name: "拒绝",
          NodeId: "",
          Id: "",
          Type: "02",
          Checked: true,
          NextNodeId: "",
          KeyWord: "",
          Answer: "不行",
        },
        {
          Name: "否定",
          NodeId: "",
          Id: "",
          Type: "01",
          Checked: false,
          NextNodeId: "",
          KeyWord: "",
          Answer: "不可以",
        },
        {
          Name: "默认",
          NodeId: "",
          Id: "",
          Type: "03",
          Checked: true,
          NextNodeId: "",
          KeyWord: "",
          Answer: "行",
        },
      ],
      //    wordWidth:16,//一个字的大小
      scrollbarTop: 20000,
      scrollbarLeft: 20000,
      LargeNum: 1,
      AddNode: [],
      TopNav: [
        { name: "普通节点", type: "00" },
        { name: "跳转节点", type: "01" },
      ],
      FlowId: "", //流程Id,
      BranchDisable: false, //分支禁用
      obj: {
        Id: "",
        RobotId: this.$route.query.Id,
        Name: "",
        Branchs: [],
        Nodes: [],
      },
      IsRbg: "",
      DragArrowValue: {},
      AddEditBulletframe: 0, //默认打开分支新增弹框,0代表新增 1代表编辑
      FieldList: [], //问卷题目数组
      TopicId: {
        id: "",
      }, //题目id
      usednav: [], //旧值
      LineId: "",
      startNode: {}, // 拖拽开始节点
      endNode: {}, // 拖拽开始节点
      nextNodeType: "", // 去往下一个节点的类型
      lastenter: null,
      isid: "",
    };
  },
  props: {
    changeValue: {
      type: String,
      default: "",
    },
  },
  watch: {
    changeValue(oldValue, newValue) {
      if (oldValue != newValue) {
        this.IsId = "";
      }
    },
  },
  destroyed() {
    //取消键盘监听事件
    document.onkeydown = null;
    clearInterval(this.timer);
  },
  created() {
    if (this.$route.query.qid != null) {
      this.ListOfTopics();
    }
    this.GetList();
    this.BranchGetList();
    // this.GetNodes()
  },
  mounted() {
    this.$refs.topology.addEventListener("scroll", this.scrool());
    document.getElementById("box").scrollTop = this.scrollbarTop;
    document.getElementById("box").scrollLeft = this.scrollbarLeft;
    this.DragArrowmouseup();
    this.keyDown();
  },
  methods: {
    beforeClose(hide) {
      if (this.className === "el-drawer__container el-drawer__open") hide();
    },
    // 打开编辑节点模态框鼠标按下时事件
    drawerMousedown(e) {
      this.className = e.target.className;
    },
    // //右键点击nav
    contextmenu(e, item) {
      this.leftSound = e.pageX + this.scrollbarLeft + 80;
      this.topSound = e.pageY + this.scrollbarTop + 15 - 90;
      this.navItemValue = item;
    },
    // 点击录音文字出现对话框
    soundRecording() {
      this.centerDialogVisible = true;
    },
    //dialog动画结束后
    AudioOpened() {
      if (this.navItemValue.SpeechConfigs[0].VoiceFile) {
        this.isAbled = true;
        this.isDelete = false;
        this.isDownload = false;
      } else {
        this.isAbled = true;
        this.isDelete = true;
        this.isDownload = true;
      }
    },
    //点击播放isPlay
    handlePlay() {
      this.$nextTick(() => {
        let AudioPlay = document.getElementById("audioPlay");
        if (!AudioPlay.duration) return;
        if (
          this.navItemValue.SpeechConfigs[0].VoiceFile.indexOf(".wav") !== -1
        ) {
          AudioPlay.src += `?r=${Math.random() * 10}`;
        }
        AudioPlay.play();
        this.isPlay = !this.isPlay;
        // 播放时长
        this.timer = setInterval(() => {
          try {
            this.playTime = AudioPlay.currentTime;
            if (this.playTime.toFixed(1) == AudioPlay.duration.toFixed(1)) {
              this.isPlay = true;
              clearInterval(this.timer);
            }
          } catch (error) {
            this.timer = null;
          }
        }, 100);
      });
    },
    //暂停播放
    handlePausePlay() {
      clearInterval(this.timer);
      const AudioPlay = document.getElementById("audioPlay");
      AudioPlay.pause();
      // this.recorder.pausePlay() // 暂停播放
      this.isPlay = !this.isPlay;
    },
    //点击重新录音(开始录音)
    handleStart() {
      this.recorder = new Recorder();
      this.navItemValue.SpeechConfigs[0].VoiceFile = "";
      Recorder.getPermission().then(
        () => {
          this.recorder.start(); // 开始录音
          this.isStart = !this.isStart;
        },
        (error) => {
          this.$message({
            message: "请先允许该网页使用麦克风",
            type: "info",
          });
        }
      );
    },
    //点击暂停录音
    handleStop() {
      this.recorder.stop(); // 停止录音
      const blob = this.recorder.getWAVBlob(); //获取bobl文件
      this.navItemValue.SpeechConfigs[0].VoiceFile = URL.createObjectURL(blob);
      this.isStart = !this.isStart;
      this.isDownload = !this.isDownload;
      this.isDelete = !this.isDelete;
      this.isAbled = !this.isAbled;
      this.$message({
        message: "录音结束",
        type: "success",
      });
    },
    //点击下载录音
    downloadAudio() {
      const AudioUrl = this.navItemValue.SpeechConfigs[0].VoiceFile;
      if (AudioUrl) {
        this.downloadAudioStream(AudioUrl, new Date().getTime());
      } else {
        this.$message({
          type: "waring",
          message: "没有录音文件!",
        });
      }
    },
    //将src路径转化成bobl文件
    downloadBlob(url) {
      return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.onload = function () {
          if (xhr.status === 200) {
            1;
            resolve(xhr.response);
          } else {
            reject(new Error(xhr.statusText || "Download failed."));
          }
        };
        xhr.onerror = function () {
          reject(new Error("Download failed."));
        };
        xhr.send();
      });
    },
    //下载录音文件
    downloadFile(url, name) {
      return this.downloadBlob(url).then((res) => {
        const objectUrl = URL.createObjectURL(res);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        a.setAttribute("href", objectUrl);
        a.setAttribute("target", "_blank");
        a.setAttribute("download", name + "." + "wav");
        a.click();
        URL.revokeObjectURL(objectUrl);
        document.body.removeChild(a);
      });
    },
    downloadAudioStream(data, name) {
      this.downloadFile(data, name);
    },
    //删除录音
    deleteAudio() {
      let AudioPlay = document.getElementById("audioPlay");
      this.$confirm("此操作将永久删除该录音, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.recorder = "";
          this.recorder = new Recorder();
          // AudioPlay.setAttribute('src',' ');
          this.navItemValue.SpeechConfigs[0].VoiceFile = "";
          this.isPlay = true;
          this.isDownload = true;
          this.isDelete = true;
          this.isAbled = true;
          this.playTime = 0;
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleAvatarSuccess(res, file) {
      if (res.Status === 1) {
        this.navItemValue.SpeechConfigs[0].VoiceFile = res.Data;
        this.isDownload = false;
        this.isDelete = false;
        this.isAbled = true;
        this.playTime = 0;
        this.$message({
          type: "success",
          message: res.Message,
        });
      } else {
        this.$message({
          type: "error",
          message: res.Message,
        });
      }
    },
    beforeAvatarUpload(file) {
      let suffix = this.getFileType(file.name);
      if (suffix !== "wav") {
        this.$message.warning(`当前文件格式不对! 请上传wav文件`);
        return false;
      }
    },
    //获取文件后缀名
    getFileType(fileName) {
      let fileWav = fileName.split(".")[1];
      return fileWav;
    },
    //关闭dialog
    handleCloseAudio() {
      this.recorder = new Recorder();
      this.isStart = true;
      this.isDownload = true;
      this.isDelete = true;
      this.isAbled = true;
      this.playTime = 0;
      this.isPlay = true;
      clearInterval(this.timer);
      this.centerDialogVisible = false;
    },
    //点击保存录音
    UploadAudio() {
      const params = {
        RobotId: this.$route.query.Id,
        FlowId: this.FlowId,
        NodeId: this.navItemValue.Id,
        SpeechId: this.navItemValue.SpeechConfigs[0].Id,
      };
      const formData = new FormData();
      const blob = this.recorder.getWAVBlob(); // 获取wav格式音频数据
      const fileOfBlob = new File([blob], new Date().getTime() + ".wav");
      formData.append("file", fileOfBlob);
      formData.append("RobotId", this.$route.query.Id);
      formData.append("FlowId", this.FlowId);
      formData.append("NodeId", this.navItemValue.Id);
      formData.append("SpeechId", this.navItemValue.SpeechConfigs[0].Id);
      RobotCenter.UploadAudioData(formData).then((res) => {
        if (res.data.Status == 1) {
          this.navItemValue.SpeechConfigs[0].VoiceFile = res.data.Data;
          this.$message({
            type: "success",
            message: res.data.Message,
          });
        } else {
          this.$message({
            type: "error",
            message: res.data.Message,
          });
        }
      });
      this.centerDialogVisible = false;
    },
    FnMouseout() {
      this.LineId = "";
      this.isid = "";
    },
    FnMouseover(item) {
      this.LineId = item.Id;
      this.isid = item.NodeId;
    },
    FlowDialogfn() {
      if (JSON.stringify(this.usednav) !== JSON.stringify(this.nav)) {
        this.$confirm("您修改了此流程, 是否保存?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let flag = false;
            flag = this.preservation();
            if (!flag) {
              this.FlowDialog = true;
              this.FlowName = "";
            }
          })
          .catch(() => {
            this.FlowDialog = true;
            this.FlowName = "";
          });
      } else {
        this.FlowDialog = true;
        this.FlowName = "";
      }
    },
    deletecustom(item) {
      this.obj.Branchs = this.obj.Branchs.filter((row) => {
        return row.Id != item.Id;
      });
    },
    ChangeTopic(e) {
      this.form.TopicId = e.id;
      this.form.TopicType = e.dataName;
    },
    //题目列表
    async ListOfTopics() {
      const parameter = {
        projectQuestionnaireId: this.$route.query.qid,
      };
      ProjectManagement.ListOfTopicsData(parameter).then((res) => {
        if (res.data.Status == 1) {
          this.FieldList = res.data.Data;
        } else {
          alert(res.data.Message);
        }
      });
    },
    scrool() {
      if (this.$refs.topology.scrollTop != 0) {
        this.scrollbarTop = this.$refs.topology.scrollTop;
      }
    },
    Returntothepreviouslevel() {
      if (JSON.stringify(this.usednav) !== JSON.stringify(this.nav)) {
        this.$confirm("您修改了此流程, 是否保存?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let flag = false;
            flag = this.preservation();
            if (!flag) {
              this.$router.push({ path: "/RobotCenter" });
            }
          })
          .catch(() => {
            this.$router.push({ path: "/RobotCenter" });
          });
      } else {
        this.$router.push({ path: "/RobotCenter" });
      }
    },
    //确定自定义分支修改
    AddCustomBranch() {
      this.BranchObj.KeyWord = "";
      this.dynamicTags.forEach((item, i, arr) => {
        if (arr.length - 1 == i) {
          this.BranchObj.KeyWord += item;
        } else {
          this.BranchObj.KeyWord += item + ",";
        }
      });
      this.obj.Branchs.forEach((item) => {
        if (item.Id == this.BranchObj.Id) {
          item.KeyWord = this.BranchObj.KeyWord;
          item.Type = this.BranchObj.Type;
          item.Answer = this.BranchObj.Answer;
        }
      });
      this.CustomAddBranchDialog = false;
    },
    Editcustom(item) {
      this.dynamicTags = item.KeyWord.split(",");
      this.BranchObj = JSON.parse(JSON.stringify(item));
      this.CustomAddBranchDialog = true;
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.setNewVal(this.inputValue).replace(/，/gi, ",");

      if (inputValue && inputValue.indexOf(",") != -1) {
        inputValue.split(",").forEach((item, index) => {
          if (item !== "") {
            this.dynamicTags.push(item);
          }
        });
      } else if (inputValue && inputValue.indexOf(",") == -1) {
        this.dynamicTags.push(inputValue);
      }
      this.dynamicTags = [...new Set(this.dynamicTags)];
      this.inputVisible = false;
      this.inputValue = "";
    },
    //替换字符串中的所有特殊字符（包含空格）
    setNewVal(valueStr) {
      const pattern =
        /[`~!@#$^&*()=|{}':;+'\\\[\]\.<>\/?~！@#￥……&*（）——|{}【】·‘’'；：""'。、？-\s]/g;
      return valueStr.replace(pattern, "");
    },
    // 拖动箭头值
    DragArrowmouseup() {
      document.onmouseup = (e) => {
        let flag = false;
        document.onmousemove = null;
        e.path?.forEach((item) => {
          if (item.id != "") {
            if (this.DragArrowValue.NodeId != item.id) {
              this.nav.forEach((row) => {
                if (row.Id == item.id) {
                  // row.PrevNodeId=this.id
                  flag = true;
                  this.list.forEach((p) => {
                    if (p.Id == this.DragArrowValue.Id) {
                      p.BoxArrowId = row.Id;
                      (p.ArrowX1 = row.Left + row.Width / 2), //箭头点x轴
                        (p.ArrowY1 = row.Top - 10), //箭头点y轴
                        (p.ArrowX2 = row.Left + 16 + row.Width / 2), //箭头点偏移x轴
                        (p.ArrowY2 = row.Top); //箭头点偏移y轴
                    }
                  });
                }
              });
            }
          }
        });
        if (!flag) {
          this.list.forEach((p) => {
            if (p.Id == this.DragArrowValue.Id) {
              p.BoxArrowId = this.DragArrowValue.BoxArrowId;
              (p.ArrowX1 = this.DragArrowValue.ArrowX1), //箭头点x轴
                (p.ArrowY1 = this.DragArrowValue.ArrowY1), //箭头点y轴
                (p.ArrowX2 = this.DragArrowValue.ArrowX2), //箭头点偏移x轴
                (p.ArrowY2 = this.DragArrowValue.ArrowY2); //箭头点偏移y轴
            }
          });
        }
      };
    },
    //#region
    takePhoto(e, item) {
      this.nav = this.nav.map((row) => {
        if (row.Id == item.BoxArrowId) {
          row.PrevNodeId = "";
        }
        return row;
      });
      // this.Bid=item.NodeId
      // this.id=item.NodeId
      this.DragArrowValue = JSON.parse(JSON.stringify(item));
      document.onmousemove = (e) => {
        this.list.forEach((row) => {
          if (row.Id == item.Id) {
            (row.ArrowX1 = e.pageX + this.scrollbarLeft + 80), //箭头点x轴
              (row.ArrowY1 = e.pageY + this.scrollbarTop - 90), //箭头点y轴
              (row.ArrowX2 = e.pageX + this.scrollbarLeft + 80), //箭头点偏移x轴
              (row.ArrowY2 = e.pageY + this.scrollbarTop - 90); //箭头点偏移y轴
          }
        });
      };
    },
    //打开新增自定义分支弹框
    CustomAnswerbranchFn() {
      this.AddEditBulletframe = 0;
      this.title = "新增分支";
      this.BranchDisable = false;
      this.dynamicTags = [];
      this.BranchObj.Checked = false;
      this.BranchObj.Name = "";
      this.BranchObj.Type = "";
      this.BranchObj.KeyWord = "";
      this.BranchObj.Answer = "";
      this.AddBranchDialog = true;
    },
    //打开回答分支编辑弹框
    AnswerbranchFn(item) {
      if (item.KeyWord == "") {
        this.dynamicTags = [];
      } else {
        this.dynamicTags = item.KeyWord.split(",");
      }
      this.AddEditBulletframe = 1;
      this.title = "编辑分支";
      this.BranchDisable = true;
      this.BranchObj = JSON.parse(JSON.stringify(item));
      this.AddBranchDialog = true;
    },
    //新增自定义分支
    AddBranch() {
      this.BranchObj.KeyWord = "";
      this.dynamicTags.forEach((item, i, arr) => {
        if (arr.length - 1 == i) {
          this.BranchObj.KeyWord += item;
        } else {
          this.BranchObj.KeyWord += item + ",";
        }
      });
      if (this.AddEditBulletframe == 0) {
        this.BranchObj.Id = this.newGuid();
        this.obj.Branchs.push(JSON.parse(JSON.stringify(this.BranchObj)));
      } else {
        this.form.BranchConfigs.forEach((item) => {
          if (this.BranchObj.Name == item.Name) {
            item.KeyWord = this.BranchObj.KeyWord;
          }
        });
      }
      this.AddBranchDialog = false;
    },
    NextFlowIdFn(e) {
      this.form.JumpConfig.NextFlowName = e.Name;
      this.form.JumpConfig.NextFlowId = e.Id;
    },
    async BranchGetList() {
      let obj = {
        RobotId: this.$route.query.Id,
      };
      ProcessList.BranchGetListData(obj).then((res) => {
        if (res.data.Status == 1) {
          this.obj.Branchs = res.data.Data;
        } else {
          alert(res.data.Message);
        }
      });
    },
    //修改流程
    ModifyAddFlow() {
      let obj = {
        Name: this.ModifyFlowName,
        RobotId: this.$route.query.Id,
        Id: this.FlowId,
      };
      ProcessList.EditFlowData(obj).then((res) => {
        if (res.data.Status == 1) {
          this.FlowList = this.FlowList.map((item) => {
            if (this.FlowId == item.Id) {
              item.Name = this.ModifyFlowName;
            }
            return item;
          });
        }
      });
    },
    ModifyFlowFn(item) {
      this.ModifyFlowName = item.Name;
      this.ModifyFlowDialog = true;
    },
    ToggleRulesFn(index) {
      this.form.SwitchConfig.Value = index;
      this.isindex = index;
    },
    //
    NextActionFn(e) {
      this.form.JumpConfig.NextAction = e;
    },
    keyDown() {
      document.onkeydown = (e) => {
        //事件对象兼容
        let e1 =
          e || event || window.event || arguments.callee.caller.arguments[0];
        if (e1 && e1.keyCode == 46) {
          if (this.IsId != "") {
            this.LineId = "";
            this.nav = this.nav.filter((item, index) => {
              if (item.BranchConfigs) {
                item.BranchConfigs.forEach((node, index) => {
                  if (node.NextNodeId === this.IsId) {
                    item.BranchConfigs[index].NextNodeId = "";
                  }
                });
              }
              if (item.PrevNodeId === this.IsId) {
                this.nav[index].PrevNodeId = "";
              }

              return this.IsId != item.Id;
            });
            this.list = this.list.filter((item) => {
              return this.IsId != item.BoxArrowId;
            });
            this.list = this.list.filter((item) => {
              if (item.NodeId === this.IsId) {
                item.NodeId = "";
                item.ArrowX1 = "";
                item.ArrowX2 = "";
                item.ArrowY2 = "";
                item.ArrowY2 = "";
              }
              //  : item.NodeId
              //   item.BoxArrowId = item.
              return this.IsId != item.NodeId;
            });
            this.IsId = "";
          }
          // if (this.LineId != '') {
          //删除线
          if (this.LineId == "") {
            return;
          }
          this.list = this.list.filter((item) => {
            return this.LineId != item.Id;
          });
          this.nav.forEach((item) => {
            if (this.isid == item.Id) {
              item.BranchConfigs = item.BranchConfigs.map((row) => {
                if (this.LineId == row.Id) {
                  row.NextNodeId = "";
                }
                return row;
              });
            }
          });
        }
      };
    },
    FlowListFn(item) {
      document.getElementById("box").scrollTop = 20000;
      document.getElementById("box").scrollLeft = 20000;
      if (JSON.stringify(this.usednav) !== JSON.stringify(this.nav)) {
        this.$confirm("您修改了此流程, 是否保存?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let flag = false;
            flag = this.preservation();
            if (!flag) {
              this.obj.Name = item.Name;
              this.FlowId = item.Id;
              this.GetNodes();
            }
          })
          .catch(() => {
            this.obj.Name = item.Name;
            this.FlowId = item.Id;
            this.GetNodes();
          });
      } else {
        this.obj.Name = item.Name;
        this.FlowId = item.Id;
        this.GetNodes();
      }
    },
    BorderFn(item) {
      this.IsId = item.Id;
    },
    //渲染流程图
    GetNodes() {
      let obj = {
        Id: this.FlowId,
        RobotId: this.$route.query.Id,
      };
      ProcessList.GetNodesData(obj).then((res) => {
        if (res.data.Status == 1) {
          this.usednav = JSON.parse(JSON.stringify(res.data.Data.Nodes));
          this.nav = JSON.parse(JSON.stringify(res.data.Data.Nodes));
          this.list = [];
          this.$nextTick(() => {
            this.nav.forEach((item) => {
              if (item.PrevNodeId == null || item.PrevNodeId == "") {
                document.getElementById("box").scrollTop = item.Top - 200;
              }
              let num = 0;
              if (item.BranchConfigs) {
                item.BranchConfigs.forEach((row) => {
                  let box = document.getElementById(`${row.Id}`);
                  num += box.clientWidth + 10;
                  if (row.NextNodeId != "") {
                    let obj = {};
                    if (item.Id == row.NodeId) {
                      obj = {
                        IsRbg:
                          row.Type == "00" ? "#3978E7" : row.Type == "02" ? "#FA5A8E" : row.Type == "03" ? "#000" : row.Type == "01" ? "#FF9900" : "#c45656",
                        BoxArrowId: row.NextNodeId,
                        NodeId: row.NodeId, //开始连线的节点
                        Id: row.Id, //每个回答分支的id和线的id
                        OriginX1: item.Left + (num - box.clientWidth / 2), //原点x轴
                        OriginY1: item.Top + (item.Height - 10), //原点y轴
                        OriginX2: item.Left + (num - box.clientWidth / 2) + 50, //原点偏移x轴
                        OriginY2: item.Top + (item.Height - 10) + 50, //原点偏移y轴
                        Index: row.Index,
                      };
                    }
                    this.nav.forEach((m) => {
                      if (row.NextNodeId == m.Id) {
                        obj.ArrowX1 = m.Left + m.Width / 2;
                        obj.ArrowY1 = m.Top - 10;
                        obj.ArrowX2 = m.Left + m.Width / 2 - 50;
                        obj.ArrowY2 = m.Top - 50;
                      }
                    });
                    this.list.push(obj);
                  }
                });
              }
            });
          });
        }
      });
    },
    //#endregion
    //保存话术
    preservation() {
      let quantity = 0;
      let NameFlag = false;
      let TextFlag = false;
      this.nav.forEach((item) => {
        if (item.PrevNodeId == "") {
          quantity++;
        }
        if (item.Name == "") {
          NameFlag = true;
        }
        if (item.Type == "00") {
          item.SpeechConfigs.forEach((row) => {
            if (row.Text == "") {
              TextFlag = true;
            }
          });
        }
      });
      if (NameFlag) {
        this.$message({
          message: "节点名称不能为空!",
          type: "warning",
        });
        return;
      }
      if (TextFlag) {
        this.$message({
          message: "节点内容不能为空!",
          type: "warning",
        });
        return;
      }
      if (quantity > 1) {
        this.$message({
          message: "存在未连线节点",
          type: "warning",
        });
        return;
      }
      this.obj.Id = this.FlowId;
      this.obj.Nodes = this.nav;
      this.$refs.deviationCharts.forEach((i) => {
        this.obj.Nodes.forEach((j) => {
          if (i.getAttribute("id") === j.Id) {
            if (i.children[2].clientWidth + 20 < 240) {
              j.Width = 260;
            } else {
              j.Width = i.children[2].clientWidth + 20;
            }
            j.Height = i.clientHeight;
          }
        });
      });
      ProcessList.AddFloSaveData(this.obj).then((res) => {
        if (res.data.Status == 1) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.usednav = this.nav;
        } else {
          this.$message.error(res.data.Message);
        }
      });
    },
    // #region
    //删除流程
    DeleteFlow(item) {
      this.$confirm("此操作将永久删除流程, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let list = [];
          list.push(item.Id);
          ProcessList.DeleteFlowData(list).then((res) => {
            if (res.data.Status == 1) {
              this.FlowList = this.FlowList.filter((row) => {
                return row.Id != item.Id;
              });
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              if (this.FlowList.length > 0) {
                this.FlowId = this.FlowList[0].Id;
              }
              this.GetNodes();
            }
          });
        })
        .catch(() => { });
    },
    // 点击空白部分
    handleBlank(e) {
      const ClassName = e.target._prevClass;
      const ClassName1 = e.target.parentNode._prevClass;
      const ClassName2 = e.target.parentNode.parentNode._prevClass;
      if (
        !(
          ClassName.includes("name") ||
          ClassName1.includes("name") ||
          ClassName2.includes("name")
        )
      ) {
        this.IsId = "";
        this.leftSound = 0;
        this.topSound = 0;
      }
    },
    //流程列表
    GetList() {
      let obj = {
        Key: "",
        RobotId: this.$route.query.Id,
      };
      ProcessList.GetListData(obj).then((res) => {
        if (res.data.Status == 1) {
          if (res.data.Data.length != 0) {
            console.log(res.data);
            this.FlowList = res.data.Data;
            this.FlowId = res.data.Data[0].Id;
            this.obj.Name = res.data.Data[0].Name;
            this.GetNodes();
          }
        }
      });
    },
    //添加流程
    AddFlow() {
      let obj = {
        Name: this.FlowName,
        RobotId: this.$route.query.Id,
        Index: this.FlowList.length,
      };
      ProcessList.AddFlowData(obj).then((res) => {
        if (res.data.Status == 1) {
          this.FlowList.push(res.data.Data);
          this.FlowId = res.data.Data.Id;
          this.GetNodes();
          this.FlowDialog = false;
        }
      });
    },
    //放大缩小
    LargeNumFn(num) {
      if (num == 1) {
        this.percentage += 10;
        this.LargeNum += 0.1;
        if (this.LargeNum >= 2) {
          this.LargeNum = 2;
          this.percentage = 200;
          return;
        }
      }
      if (num == 2) {
        this.percentage -= 10;
        this.LargeNum -= 0.1;
        if (this.LargeNum <= 0.1) {
          this.LargeNum = 0.1;
          this.percentage = 10;
          return;
        }
      }
    },
    ChangeTitle(e) {
      if (e.length >= 10) {
        this.form.width = e.length * 16 + 100;
      }
    },
    ChangeTextarea(e, item) {
      this.form.SpeechConfigs.forEach((row) => {
        if (row.Id == item.Id) {
          row.Text = e;
          this.CalculationHeight(e);
        }
      });
    },
    //计算盒子高度
    CalculationHeight(e) {
      this.$nextTick(() => {
        this.$refs.deviationCharts.forEach((item) => {
          if (this.form.Id == item.id) {
            if (e == "") {
              this.form.Height = this.boxHeight;
            } else {
              this.form.Height =
                this.boxHeight + item.childNodes[1].offsetHeight + 10;
            }
            this.$nextTick(() => {
              this.list = this.list.map((row) => {
                this.form.BranchConfigs.forEach((p) => {
                  if (p.Id == row.Id) {
                    row.OriginY1 = this.form.Top + this.form.Height - 10;
                    row.OriginY2 = this.form.Top + this.form.Height - 10;
                  }
                });
                return row;
              });
            });
          }
        });
      });
    },
    //回答分支
    BranchFn(e, item) {
      let total = 0;
      let Index = 1000;
      Index = item.Index;
      let box = null;
      let flag = false;
      if (e) {
        item.Id = this.newGuid();
        item.NodeId = this.form.Id;
        item.Index = this.form.BranchConfigs.length + 1;
        this.form.BranchConfigs.push(item); //增加分支
        this.$nextTick(() => {
          this.form.BranchConfigs.forEach((row) => {
            box = document.getElementById(`${row.Id}`);
            total += box.clientWidth + 10;
          });
          if (total > 192) {
            box = document.getElementById(`${item.Id}`);
            this.form.Width = this.form.Width + box.clientWidth + 10;
          }
        });
      } else {
        // this.$nextTick(()=>{
        box = document.getElementById(`${item.Id}`);
        this.list = this.list.filter((row) => {
          //去线
          if (row.NodeId == item.NodeId) {
            if (row.Index > Index) {
              row.Index = row.Index - 1;
              row.OriginX1 = row.OriginX1 - (box.clientWidth + 10);
              row.OriginX2 = row.OriginX2 - (box.clientWidth + 10);
            }
          }
          return row.Id != item.Id;
        });
        // })
        this.form.BranchConfigs.forEach((row) => {
          if (flag) {
            row.Index = row.Index - 1;
          }
          if (row.Id == item.Id) {
            flag = true;
          }
          box = document.getElementById(`${row.Id}`);
          total += box.clientWidth + 10;
        });
        if (total <= 192) {
          this.form.Width = 260;
        } else {
          box = document.getElementById(`${item.Id}`);
          this.form.Width = this.form.Width - box.clientWidth + 10;
        }
        this.form.BranchConfigs = this.form.BranchConfigs.filter((row) => {
          //去分支
          return item.Name != row.Name;
        });
      }
    },
    //确定修改分支属性
    queding() {
      if (this.NextAction === '02' && !this.NextFlowId.Id) return this.$message.warning("请选择流程")
      this.nav = this.nav.map((item) => {
        if (item.Id == this.form.Id) {
          item = JSON.parse(JSON.stringify(this.form));
        }
        return item;
      });
      this.IsId = this.form.Id;
      this.drawer = false;
      this.NextFlowId = "";
    },
    //走取消步骤
    quedingcancel() {
      this.IsId = this.form.Id;
      this.drawer = false;
    },
    SetUp(item) {
      this.drawer = true;
      this.IsId = "";
      if (item.SwitchConfig.Value != "") {
        this.isindex = item.SwitchConfig.Value;
      }
      this.TopicId = {};
      this.TopicId.id = item.TopicId;
      this.SpeechConfigs = JSON.parse(JSON.stringify(item.SpeechConfigs));
      this.form = JSON.parse(JSON.stringify(item));
      if (item.Type == "00") {
        this.NodeList = this.NodeList.map((row) => {
          row.Checked = false;
          if (item.BranchConfigs) {
            item.BranchConfigs.forEach((p) => {
              if (row.Name == p.Name) {
                row.KeyWord = p.KeyWord;
                row.Id = p.Id;
                row.Checked = true;
                row.NodeId = p.NodeId;
                row.Index = p.Index;
              }
            });
          }
          return row;
        });
        this.obj.Branchs = this.obj.Branchs.map((row) => {
          row.Checked = false;
          if (item.BranchConfigs) {
            item.BranchConfigs.forEach((p) => {
              if (row.Name == p.Name) {
                row.KeyWord = p.KeyWord;
                row.Id = p.Id;
                row.Checked = true;
                row.NodeId = p.NodeId;
                row.Index = p.Index;
              }
            });
          }
          return row;
        });
      }
      if (item.Type == "01") {
        this.NextAction = item.JumpConfig.NextAction;
        this.NextFlowId.Id = item.JumpConfig.NextFlowId;
      }
    },
    sliderDown(e) {
      if (e.target.id == "nav_box") {
        let box = document.getElementById("box");
        box.onmousemove = (e) => {
          //    this.scrollbar-=e.movementX
          this.scrollbarLeft -= e.movementX;
          this.scrollbarTop -= e.movementY;
          box.scrollLeft -= e.movementX;
          box.scrollTop -= e.movementY;
        };
        document.onmouseup = (e) => {
          box.onmousemove = null;
        };
      }
    },
    // #endregion
    //大盒子拖拽结束
    boxdragend(e, n) {
      let x = e.pageX - this.offsetX + this.scrollbarLeft + 38; //算出拖拽后盒子letf位置0,
      let y = e.pageY - this.offsetY + this.scrollbarTop - 77; //算出拖拽后盒子top位置0,

      //盒子位置移动
      this.nav = this.nav.map((item) => {
        if (this.id == item.Id) {
          item.Left = x;
          item.Top = y;
        }
        return item;
      });
      //线位置移动
      let tar = e.target.childNodes[2].childNodes; //盒子拖拽后的回答分支的
      this.list = this.list.map((item) => {
        let distance = 0;
        if (n.BranchConfigs) {
          n.BranchConfigs.forEach((row) => {
            //原始点
            if (row.Id == item.Id) {
              tar.forEach((p) => {
                if (p.offsetWidth != 0) {
                  distance += p.offsetWidth + 10;
                }
                if (p.id == row.Id) {
                  item.OriginX1 = x + (distance - p.offsetWidth / 2);
                  item.OriginX2 = x + (distance - p.offsetWidth / 2) + 50;
                }
              });
              item.OriginY1 = y + (e.target.offsetHeight - 10);
              item.OriginY2 = y + (e.target.offsetHeight - 10) + 50;
            }
            //原始点
          });
        }
        //箭头点
        if (item.BoxArrowId == this.id) {
          item.ArrowX1 = x + e.target.offsetWidth / 2;
          item.ArrowY1 = y - 10;
          item.ArrowX2 = x + e.target.offsetWidth / 2 - 50;
          item.ArrowY2 = y - 50;
        }
        return item;
        //箭头点
      });
    },
    //小盒子拖拽结束
    dragend() {
      if (this.flag == false) {
        this.list = this.list.filter((item) => {
          return this.Id != item.Id;
        });
      }
    },
    //离开元素时候触发
    dragleave(e) {
      if (this.lastenter === e.target) {
        this.Bid = "";
      }
    },
    //拖进容器时触发
    dragenter(e, item) {
      this.lastenter = e.target;
      if (this.isid != item.Id) {
        this.Bid = JSON.parse(JSON.stringify(item.Id));
      }
    },
    //解决拖进容器中不触发drop事件
    dragover(e) {
      e.preventDefault();
    },
    //拖进容器时释放触发
    drop(e, item) {
      // this.endNode = item
      if (item.Id != this.id) {
        this.flag = true;
        if (this.flag) {
          this.Bid = "";
          this.list = this.list.map((row) => {
            if (this.Id == row.Id) {
              row["BoxArrowId"] = item.Id; //储存一下连接的箭头点节点的id
              row.ArrowX1 = item.Left + item.Width / 2;
              row.ArrowY1 = item.Top - 10;
            }
            return row;
          });
          this.nav.forEach((m) => {
            if (m.Id == this.id) {
              //   m.BranchConfigs[this.nextNodeType].NextNodeId = item.Id
              m.BranchConfigs.forEach((row) => {
                if (row.Id == this.Id) {
                  row.NextNodeId = item.Id;
                }
              });
            }
            if (item.Id == m.Id) {
              m.PrevNodeId = this.id;
              // m.PrevNodeId = this.startNode.Id
            }
          });
        }
      }
    },
    //大盒元素开始拖拽时候触发
    boxdragstart(e, item) {
      this.offsetX = e.offsetX;
      this.offsetY = e.offsetY;
      this.id = item.Id;
    },
    //小盒子元素开始拖拽时候触发
    dragstart(e, row, item) {
      let dom = document.getElementById(`${row.Id}`);
      if (window.getComputedStyle) {
        //判断是否存在getComputedStyle方法
        this.IsRbg = document.defaultView.getComputedStyle(dom, null).color;
      }
      this.flag = false;
      this.x1 =
        e.pageX -
        e.offsetX +
        e.target.offsetWidth / 2 +
        this.scrollbarLeft +
        90;
      this.y1 =
        e.pageY -
        e.offsetY +
        e.target.offsetHeight +
        this.scrollbarTop +
        10 -
        90;
      this.Id = row.Id;
      this.id = item.Id;
      // this.startNode = item
      e.stopPropagation(); //阻止默认行为冒泡
    },
    updated(startX, startY, x3, x4, endX, endY, y3, y4) {
      // return `M ${startX},${startY}  C ${x3} ${x4}, ${y3} ${y4}, ${endX},${endY}`
      return `M ${startX},${startY}  C ${x3}, ${x4} ${y3} ,${y4} ${endX} ${endY}`;
    },
    arrow(endX, endY) {
      return `M ${endX} ${endY} l 5 0 l -5 10 l -5 -10 Z`;
      // return `M ${startX},${startY}  C ${x3} ${x4}, ${y3} ${y4}, ${endX},${endY}`
    },
    newGuid() {
      var guid = "";
      for (var i = 1; i <= 32; i++) {
        var n = Math.floor(Math.random() * 16.0).toString(16);
        guid += n;
        if (i == 8 || i == 12 || i == 16 || i == 20) guid += "-";
      }
      return guid;
    },
    // 拖拽结束, 创建节点
    handleDragEnd(e, item) {
      if (e.offsetY > 0) {
        let id = this.newGuid(); //节点id
        let letfx = 0;
        let topy = 0;
        let obj = 0;
        if (this.LargeNum == 1) {
          letfx = e.pageX - e.target.offsetWidth / 2 + this.scrollbarLeft + 80;
          topy = e.pageY - 45 + this.scrollbarTop - 90;
        }
        if (item.type == "00") {
          obj = {
            Id: id,
            DefaultName: "",
            Name: "普通节点",
            PrevNodeId: "", //上一个节点id
            TopicId: "", //题目id
            TopicType: "", //题目题型
            SpeechConfigs: [
              {
                Id: this.newGuid(),
                NodeId: id,
                Text: "",
                VoiceFile: "",
              },
            ], //话术
            Left: letfx,
            Top: topy,
            Type: item.type,
            BranchConfigs: [
              {
                Name: "肯定",
                NodeId: id,
                Id: this.newGuid(),
                Type: "00",
                Checked: true,
                NextNodeId: "",
                KeyWord: "",
                Answer: "",
              },
              {
                Name: "拒绝",
                NodeId: id,
                Id: this.newGuid(),
                Type: "02",
                Checked: true,
                NextNodeId: "",
                KeyWord: "",
                Answer: "",
              },
              {
                Name: "默认",
                NodeId: id,
                Id: this.newGuid(),
                Type: "03",
                Checked: true,
                NextNodeId: "",
                KeyWord: "",
                Answer: "",
              },
            ],
            JumpConfig: [], //跳转节点数据
            SwitchConfig: { Id: this.newGuid(), NodeId: id, Value: "00" },
            Width: this.boxWidth,
            Height: this.boxHeight,
            LabelConfigs: [],
          };
        } else {
          obj = {
            Id: id,
            DefaultName: "",
            Name: "跳转节点",
            PrevNodeId: "", //上一个节点id
            SpeechConfigs: [
              {
                Id: this.newGuid(),
                NodeId: id,
                Text: "",
                VoiceFile: "",
              },
            ], //话术
            Left: letfx,
            Top: topy,
            Type: item.type,
            BranchConfigs: [],
            JumpConfig: {
              Id: this.newGuid(),
              NodeId: id,
              NextAction: "01",
              NextFlowId: "",
            }, //跳转节点数据
            SwitchConfig: { Id: this.newGuid(), NodeId: id, Value: "" },
            Width: this.boxWidth,
            Height: this.boxHeight,
            LabelConfigs: [],
          };
        }
        this.nav.push(obj);
      }
    },
    //小盒子拖拽过程中一直触发,开始连线
    Drawaline(e, i) {
      let obj = {
        Index: i + 1,
        IsRbg: this.IsRbg,
        NodeId: this.id, //开始连线的节点
        Id: this.Id, //每个回答分支的id和线的id
        OriginX1: this.x1 - 50, //原点x轴
        OriginY1: this.y1, //原点y轴
        OriginX2: this.x1 + 50, //原点偏移x轴
        OriginY2: this.y1 + 50, //原点偏移y轴
        ArrowX1: e.pageX + this.scrollbarLeft + 80, //箭头点x轴
        ArrowY1: e.pageY + this.scrollbarTop - 90, //箭头点y轴
        ArrowX2: e.pageX + this.scrollbarLeft - 50 + 80, //箭头点偏移x轴
        ArrowY2: e.pageY + this.scrollbarTop - 50 - 90, //箭头点偏移y轴
      };
      if (this.list.length == 0) {
        this.list.push(obj);
      } else {
        let show = this.list.find((item) => item.Id == this.Id);
        if (show == null) {
          this.list.push(obj);
        } else {
          this.list = this.list.map((item) => {
            if (this.Id == item.Id) {
              item = obj;
            }
            return item;
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .el-popover .popperClass{
//   // min-width: 50px !important;
// }
.iconAudio {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 20px;
}

.iconAudio img {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin: 0 5px 0 5px;
}

.contentctx {
  width: 100px;
  height: 30px;
  z-index: 999;
  background-color: #fff;
  position: fixed;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #cfd0d2;

  .copyValue,
  .soundRecording {
    padding: 5px 12px;
    text-align: center;
    cursor: pointer;
  }

  .soundRecording:hover {
    background-color: rgb(245, 245, 245);
  }

  .copyValue:hover {
    background-color: rgb(245, 245, 245);
  }
}

.nodebackground {
  background: #ebeef5 !important;
}

//    ::v-deep .el-drawer.rtl{
//         width: 25% !important;
//   }

::v-deep .el-textarea__inner {
  height: 100px;
}

.form_btn {
  text-align: center;
}

.el-tag+.el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.sure {
  //肯定
  background: #eff5f9 !important;
  color: #3978e7 !important;
}

.refuse {
  //拒绝
  background: #ffeff3 !important;
  color: #fa5a8e !important;
}

.default {
  //默认
  background: #cfd0d2 !important;
  color: #000 !important;
}

.negative {
  //否定
  background: #fff3e7 !important;
  color: #ff9900 !important;
}

.neutral {
  //中性
  background: #d1edc4 !important;
  color: #c45656 !important;
}

.form_ToggleRules {
  display: flex;
  align-items: center;

  .ToggleRules {
    border: 1px solid #dfdfdf;
    padding: 0px 10px 0px 10px;
  }
}

.ClassBorder {
  border: 1px solid #3978e7 !important;
}

.function_bottom {
  width: 80%;
  position: fixed;
  bottom: 3px;
  right: 24px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;

  .LargeNumFn {
    display: flex;
    align-items: center;

    .percentage {
      margin-right: 20px;
      margin-left: 20px;
    }
  }

  .baocun {
    margin-right: 40px;
  }
}

::v-deep .el-popconfirm__main {
  justify-content: center !important;
}

::v-deep .el-popconfirm__action {
  text-align: center !important;
}

.form_box {
  padding: 20px;
  padding-bottom: 0;
  box-sizing: border-box;
  height: 90%;
  overflow-y: auto;
}

.Answerbranch_custom {
  .Answerbranch_item {
    min-width: 140px;

    .el-icon-edit {
      font-size: 16px;
      margin-left: 14px;
      cursor: pointer;
    }

    .el-icon-delete {
      font-size: 16px;
      margin-left: 10px;
      cursor: pointer;
    }

    :hover {
      color: #319efd;
    }
  }
}

.Answerbranch {
  width: 280px;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #e3e3e3;
  box-sizing: border-box;

  .Answerbranch_item {
    min-width: 140px;

    .el-icon-edit {
      font-size: 16px;
      margin-left: 20px;
      cursor: pointer;
    }

    :hover {
      color: #319efd;
    }
  }
}

// .topology::-webkit-scrollbar {
//   display: none;
// }

.custom_add {
  display: flex;
  align-items: center;

  .add_branch {
    color: #319efd;
    margin-left: 40px;
  }
}

.BorderClass {
  border: 1px solid #319efd !important;
}

.BackgroundClass {
  background: #f0f9ff !important;
}

.Speech {
  display: flex;

  // padding: 20px;
  // box-sizing: border-box;
  .Speech_left {
    width: 242px !important;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #e3e3e3;
    margin-right: 20px;
    height: calc(91vh - 130px);

    .left_1 {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 34px;
      background: rgba(51, 136, 255, 1);
      border-radius: 4px;
      color: #fff;
      margin-bottom: 10px;
      width: 221px;
    }

    .left_2 {
      margin-bottom: 10px;
    }

    .left_3 {
      overflow-y: auto;
      height: calc(100% - 84px);

      .left_list {
        background: rgba(248, 249, 251, 0.99);
        min-height: 80px;
        max-height: 100px;
        padding: 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
        border-radius: 10px;
        cursor: pointer;

        .left_item1 {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;

          .el-icon-edit-outline {
            margin-right: 10px;
          }
        }

        .left_item2 {
          font-size: 16px;
          color: #666666;
        }
      }
    }
  }

  .Speech_right {
    // width: 100%;
  }
}

.top_nav {
  display: flex;
  justify-content: space-between;

  .top_nav1 {
    display: flex;

    .top {
      width: 107px;
      height: 34px;
      border: 1px solid #319efd;
      border-radius: 4px;
      margin-bottom: 10px;
      color: #319efd;
      text-align: center;
      line-height: 34px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
}

.topology {
  width: 80vw;
  // height: 500px;
  height: 34.6vw;
  // height: 20%;
  overflow: auto;
  user-select: none;

  // overflow: hidden;
  .nav {
    width: 40000px;
    height: 40000px;
    background: rgba(255, 255, 255, 0.39);
    z-index: 0;
    position: relative;
    top: -180px;
    left: -360px;
    background-image: linear-gradient(90deg,
        rgba(210, 200, 195, 0.12) 10%,
        rgba(0, 0, 0, 0) 15%),
      linear-gradient(rgba(210, 200, 195, 0.12) 10%, rgba(0, 0, 0, 0) 15%);
    background-size: 20px 20px;
    transform: scale(var(--LargeNum));

    .nav_item {
      box-sizing: border-box;
      padding: 10px;
      background: #fff;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.16);
      transition: all 0.2s ease 0s;
      border-radius: 10px;
      border: 1px solid #eee;
      display: flex;
      flex-direction: column;

      .title {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        width: fit-content;
      }

      .name {
        width: 100%;
        margin-bottom: 20px;
        word-break: break-all !important;
        white-space: normal !important;
        overflow-wrap: break-word !important;
        line-height: 21px;
        font-size: 14px;
        color: #707070;
      }

      .item_flex {
        display: flex;
        white-space: nowrap;
        width: fit-content;

        .item_1 {
          // min-width: 40px;
          // padding: 10px;
          // height: 30px;
          // border:1px solid red;
          margin-right: 10px;
          box-sizing: border-box;
          padding: 5px;
          text-align: center;
          // cursor:pointer;
          border-radius: 4px;
          font-size: 14px;
          cursor: crosshair;
        }
      }

      .item_nextstep {
        font-size: 14px;
        color: #707070;
      }
    }

    .topology_svg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      overflow: visible;
      z-index: 1;
    }
  }
}
</style>
