// 数据统计api
import axios from "./Interceptor.js"
let address =window.global_config.BASE_URL2
let RobotCenter = {
    GetPageListData: (info) => {//机器人列表
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/GetPageList`,
            data:info
        })
    },
    AuditSubmitData: (info) => {//提交审核（用户），审核处理（管理员）
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/Audit/Submit`,
            data:info
        })
    },
    AuditGetPageListData: (info) => {//审核操作列表
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/Audit/GetPageList`,
            data:info
        })
    },
    AddtRobotCenterData: (info) => {//新增机器人
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/Add`,
            data:info
        })
    },
    EdittRobotCenterData: (info) => {//修改机器人
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/Edit`,
            data:info
        })
    },
    DeletetRobotCenterData: (info) => {//删除机器人
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/Delete`,
            data:info
        })
    },
    RobotSceneGetListData:() => {//场景列表
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/Scene/GetTree`,
        })
    },
    RobotLineGetListData: (info) => {//路线
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/Line/GetList`,
            data:info
        })
    },
    RobotSoundGetListData: (info) => {//声学模型
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/Sound/GetPageList`,
            data:info
        })
    },
    GroupGetTreeData: (info) => {//知识库分组树形结构
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/KnowledgeBase/Group/GetTree`,
            data:info
        })
    },
    KnowledgeBaseGroupAddData: (info) => {//添加分组
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/KnowledgeBase/Group/Add`,
            data:info
        })
    },
    KnowledgeBaseGroupEditData: (info) => {//修改分组
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/KnowledgeBase/Group/Edit`,
            data:info
        })
    },
    KnowledgeBaseGroupDeleteData: (info) => {//删除分组
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/KnowledgeBase/Group/Delete`,
            data:info
        })
    },
    QuestionGetPageListData: (info) => {//知识库问题分页列表
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/KnowledgeBase/Question/GetPageList`,
            data:info
        })
    },
    KnowledgeBaseQuestionAddData: (info) => {//问题新增
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/KnowledgeBase/Question/Add`,
            data:info
        })
    },
    RobotCopyData: (info) => {//复制机器人
        return axios({
            method: "post", 
            url: `${address}RobotCenter/Robot/Copy`,
            data:info
        })
    },
    UploadAudioData: (info) => { //上传录音
        return axios({
            method: "post",
            url: `${address}RobotCenter/Robot/UploadVoiceFile`,
            data:info
        })
    },
    UploadProcessMonitor: (info)=>{
        return axios({
            method:'post',
            url: `${address}RobotCenter/Robot/UploadVoiceFile/ProcessMonitor`,
            data:info
        })
    },
    UploadToRobotAi: (info)=>{
        return axios({
            method:'post',
            url: `${address}RobotCenter/Robot/UploadVoiceFile/UploadToRobotAi`,
            data:info
        })
    },
    GetChannelList: ()=>{
        return axios({
            method:'get',
            url:`${address}RobotCenter/GetChannelList`
        })
    },
    //添加动作
    AddGlobalList : (info)=>{
        return axios({
            method:'post',
            url:`${address}RobotCenter/Robot/Global/Add`,
            data:info
        })
    },
    //编辑
    EditGlobalList: (info)=>{
        return axios({
            method:'post',
            url:`${address}RobotCenter/Robot/Global/Edit`,
            data:info
        })
    },
    //获取
    GetGlobalList: (info)=>{
        return axios({
            method:'post',
            url:`${address}RobotCenter/Robot/Global/GetPageList`,
            data:info
        })
    },
    //删除
    DeleteGlobalList: (info)=>{
        return axios({
            method:'post',
            url:`${address}RobotCenter/Robot/Global/Delete`,
            data:info
        })
    },
    // 获取百应机器人
    GetRobots_BaiYing: (info)=>{
        return axios({
            method:'get',
            url:`${address}RobotCenter/Byai/GetRobots`,
            data:info
        })
    },
    // 获取线路列表
    GetPhones: (info)=>{
        return axios({
            method:'get',
            url:`${address}RobotCenter/Byai/GetPhones`,
            data:info
        })
    },
}
export default RobotCenter
