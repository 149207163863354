<template>
  <div class="RobotCenter_box">
    <div class="MenuCommon_head">
      <Hander v-if="$store.state.menuFlag"></Hander>
      <headassembly v-else></headassembly>
    </div>
    <div class="MenuCommon_body">
      <div class="title"></div>
      <div class="RobotCenter1">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/RobotCenter' }" style="color: #69696a">机器人管理</el-breadcrumb-item>
          <el-breadcrumb-item>{{ Qname }}</el-breadcrumb-item>
        </el-breadcrumb>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="主流程" name="first" style="width: 100%">
            <AIknowledgeBase :changeValue="changeValue"></AIknowledgeBase>
          </el-tab-pane>
          <el-tab-pane label="全局设置" name="second" class="tabpane">
            <div class="tabdiv">
              <el-input v-model="listQuery.Key" placeholder="请输入名称"></el-input>
              <el-button type="primary" icon="el-icon-search" size="medium" @click="search">查询</el-button>
              <el-button type="primary" icon="el-icon-plus" size="medium" @click="addvariable">新增变量</el-button>
            </div>
            <el-table ref="singleTable" :header-cell-style="{ background: 'rgb(240, 247, 253)' }" border
              :data="tableData" highlight-current-row style="width: 100%;margin-top: 23px;height: 540px;overflow:auto">
              <el-table-column type="index" width="96" label="序号" align="center"> </el-table-column>
              <el-table-column property="Name" label="名称" width="330" header-align='center'>
              </el-table-column>
              <el-table-column property="Content" label="AI回答内容" width="430" header-align='center'>
              </el-table-column>
              <el-table-column property="KeyWord" label="关键字" width="427" header-align='center'>
              </el-table-column>
              <el-table-column property="ActionName" label="动作" width="235" header-align='center'>
              </el-table-column>
              <el-table-column label="操作" width="300" header-align='center'>
                <template slot-scope="scope">
                  <div style="display: flex;flex-wrap: wrap;justify-content: space-between;padding: 0 50px;">
                    <el-link @click="Edit(scope.row)" type="primary" :underline="false"><i
                        class="el-icon-edit-outline"></i>修改</el-link>
                    <el-link @click="Delete(scope.row)" :underline="false"><i class="el-icon-delete"></i>删除</el-link>
                    <el-link @click="FollowUp(scope.row)" :underline="false">
                      <div style="display: flex;align-items: center;">
                        <img style="width: 16px;height: 16px;margin-top: 3px;"
                          src="../../assets/image/icon/takeNotes1.png" alt="">
                        查看
                      </div>
                    </el-link>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div style="width: 98%;margin: 0 auto;">
              <Pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.rows"
                @pagination="GetGlobalList" style="width: 100%;margin-top: 30px;"></Pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-drawer title="新增变量" :before-close="handleClose" :visible.sync="dialog" custom-class="demo-drawer" ref="drawer">
      <div class="demo-drawer__content">
        <el-form :model="form" ref="form" style="font-size: 16px;padding-left: 20px;padding-right: 50px;">
          <el-form-item label="名称" :label-width="formLabelWidth">
            <el-input v-model="form.Name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="AI回答内容" :label-width="formLabelWidth">
            <el-input type="textarea" :rows="8" v-model="form.Content"></el-input>
          </el-form-item>
          <el-form-item label="关键字" :label-width="formLabelWidth">
            <el-tag type="info" effect="plain" style="border: 1px dashed #DFDFDF;margin-right: 10px;"
              @click="addkeywords('add')"> +添加关键字 </el-tag>
            <el-tag v-for="tag in form.KeyWord" :key="tag" closable style="margin-right: 10px;"
              @close="handleCloseTag(tag)">
              {{ tag }}
            </el-tag>
          </el-form-item>
          <el-form-item label="动作" :label-width="formLabelWidth">
            <el-select v-model="form.Action" placeholder="">
              <el-option label="继续或重复当前话术" value="AiCallRepeat"></el-option>
              <el-option label="挂机" value="AiHangUp"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="demo-drawer__footer">
          <el-button @click="cancelForm" style="margin-right: 20px;">取 消</el-button>
          <el-button type="primary" @click="submit">保 存</el-button>
        </div>
      </div>
    </el-drawer>
    <!-- 查看drawer -->
    <el-drawer title="查看" :before-close="handleClose" :visible.sync="Follewdialog" custom-class="demo-drawer"
      ref="drawer">
      <div class="demo-drawer__content">
        <el-form :model="Followform" ref="form" style="font-size: 16px;padding-left: 20px;padding-right: 50px;">
          <el-form-item label="名称" :label-width="formLabelWidth">
            <el-input v-model="Followform.Name" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="AI回答内容" :label-width="formLabelWidth">
            <el-input type="textarea" :rows="8" v-model="Followform.Content" disabled></el-input>
          </el-form-item>
          <el-form-item label="关键字" :label-width="formLabelWidth">
            <el-tag v-for="tag in Followform.KeyWord" :key="tag" style="margin-right: 10px;" disabled>
              {{ tag }}
            </el-tag>
          </el-form-item>
          <el-form-item label="动作" :label-width="formLabelWidth">
            <el-select v-model="Followform.Action" placeholder="" disabled>
              <el-option label="继续或重复当前话术" value="AiCallRepeat"></el-option>
              <el-option label="挂机" value="AiHangUp"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
    <!-- 标签dialog -->
    <el-dialog :visible.sync="tagVisible" width="550px">
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">添加标签</span>
      </div>
      <el-row>
        <el-col :span="5">
          <span class="span">添加标签:</span>
        </el-col>
        <el-col :span="19">
          <el-input v-model="tagLabel" placeholder="请输入" style="width: 360px;height: 40px"></el-input>
        </el-col>
      </el-row>
      <el-row style="margin-top:10px;margin-bottom: 30px">
        <el-col :span="5">
          <p style="height:50px;"></p>
        </el-col>
        <el-col :span="19">
          <div class="submit-btn">
            <el-button @click="onsumbit" type="primary" style="margin-left: 0px">确定</el-button>
            <el-button @click="cancel" class="plan">取消</el-button>
            <el-button @click="reset" class="plan">重置</el-button>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 修改dialog -->
    <el-dialog :visible.sync="EditVisible" width="550px">
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">修改</span>
      </div>
      <el-form :model="Editform" ref="Editform">
        <el-form-item label="名称:" :label-width="formLabelWidth">
          <el-input v-model="Editform.Name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="名称:" :label-width="formLabelWidth">
          <el-input type="textarea" :rows="2" v-model="Editform.Content" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="关键字:" :label-width="formLabelWidth">
          <el-tag type="info" effect="plain" style="border: 1px dashed #DFDFDF;margin-right: 10px;"
            @click="addEditkeywords('edit')"> +添加关键字 </el-tag>
          <el-tag v-for="tag in Editform.KeyWord" :key="tag" closable style="margin-right: 10px;"
            @close="handleCloseEditTag(tag)">
            {{ tag }}
          </el-tag>
        </el-form-item>
        <el-form-item label="名称:" :label-width="formLabelWidth">
          <el-select v-model="Editform.Action" placeholder="">
            <el-option label="继续或重复当前话术" value="AiCallRepeat"></el-option>
            <el-option label="挂机" value="AiHangUp"></el-option>
          </el-select>
        </el-form-item>
        <div style="display: flex;justify-content: center;">
          <el-button @click="cancelEditForm" style="margin-right: 20px;">取 消</el-button>
          <el-button type="primary" @click="submitEdit">保 存</el-button>
        </div>
      </el-form>
    </el-dialog>

  </div>
</template>
<script>
import AIknowledgeBase from "../../components/RobotCenter/AIknowledgeBase.vue";
import Script from "../../components/RobotCenter/Script.vue";
import headassembly from "../../components/headassembly.vue";
import Pagination from '../../components/Pagination/index.vue'
import RobotCenter from "../../api/RobotCenter";
import Hander from "../../components/Hander.vue"
export default {
  data() {
    return {
      activeName: "first",
      i: 0,
      nav: ["话术流程设计", "公共知识库"],
      RobId: this.$route.query.Id,
      Qname: this.$route.query.Qname,
      total: 0,
      //判断是edit还是add
      keepAddOrEdit: '',
      listQuery: {
        rows: 10,
        page: 1,
        Key: ''
      },
      tagVisible: false,
      Follewdialog: false,
      tagLabel: '',
      Followform: {},
      EditVisible: false,
      tableData: [],
      form: {
        Name: '',
        Content: '',
        Action: '',
        KeyWord: []
      },
      Editform: {},
      dialog: false,
      formLabelWidth: '100px',
      changeValue: ''
    };
  },
  created() {
    this.GetGlobalList();
  },
  components: {
    headassembly,
    AIknowledgeBase,
    // Script,
    Pagination,
    Hander
  },
  methods: {
    GetGlobalList() {
      let params = {
        Key: this.listQuery.Key.trim(),
        page: this.listQuery.page,
        rows: this.listQuery.rows,
        RobotId: this.RobId
      }
      RobotCenter.GetGlobalList(params).then(res => {
        if (res.data.Status == 1) {
          this.tableData = res.data.Data.rows;
          this.total = res.data.Data.total;
        }
      })
    },
    search() {
      this.GetGlobalList();
    },
    add(index) {
      this.i = index;
    },
    ReturnToSuperior() {
      this.$router.push({
        path: "/RobotCenter",
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
      let time = new Date().getTime();
      this.changeValue = time
    },
    ProjectGetPageList(val) {
      console.log(this.listQuery, val);
    },
    //新增变量
    addvariable() {
      this.dialog = true;
    },
    //修改
    Edit(row) {
      this.Editform = JSON.parse(JSON.stringify(row));
      if (typeof this.Editform.KeyWord == 'string') {
        this.Editform.KeyWord = this.Editform.KeyWord.split(',');
      }
      this.EditVisible = true;
    },
    //删除
    Delete(row) {
      console.log(row);
      let params = [];
      params.push(row.Id);
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        RobotCenter.DeleteGlobalList(params).then(res => {
          if (res.data.Status == 1) {
            this.$message({
              type: 'success',
              message: res.data.Message
            });
            this.GetGlobalList();
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //查看
    FollowUp(row) {
      this.Followform = JSON.parse(JSON.stringify(row));
      if (typeof this.Followform.KeyWord == 'string') {
        this.Followform.KeyWord = this.Followform.KeyWord.split(',');
      }
      this.Follewdialog = true;
    },
    handleClose(done) {
      this.$confirm('确定要关闭吗？')
        .then(_ => {
          //重置form
          this.form = {
            Name: '',
            Content: '',
            Action: '',
            KeyWord: []
          };
          this.dialog = false;
          this.Follewdialog = false;
        })
        .catch(_ => { });
    },
    //取消提交
    cancelForm() {
      //重置form
      this.form = {
        Name: '',
        Content: '',
        Action: '',
        KeyWord: []
      }
      this.dialog = false;
    },
    //提交
    submit() {
      let params = {
        RobotId: this.RobId,
        Name: this.form.Name,
        Content: this.form.Content,
        Action: this.form.Action,
        KeyWord: this.form.KeyWord.join(',')
      };
      RobotCenter.AddGlobalList(params).then(res => {
        console.log(res)
        if (res.data.Status == 1) {
          //重新获取列表数据
          this.GetGlobalList();
          this.$message({
            type: 'success',
            message: res.data.Message
          })
        } else {
          this.$message({
            type: 'error',
            message: res.Message
          })
        }
      });
      this.form = {
        Name: '',
        Content: '',
        Action: '',
        KeyWord: []
      };
      this.dialog = false;

    },
    //添加关键字
    addkeywords(arg) {
      this.keepAddOrEdit = arg;
      this.tagVisible = true;
    },
    addEditkeywords(arg) {
      this.keepAddOrEdit = arg;
      this.tagVisible = true;
    },
    //删除标签
    handleCloseTag(tag) {
      this.form.KeyWord.splice(this.form.KeyWord.indexOf(tag), 1);
    },
    handleCloseEditTag(tag) {
      console.log(tag)
      this.Editform.KeyWord.splice(this.Editform.KeyWord.indexOf(tag), 1);
    },
    //添加标签
    onsumbit() {
      if (this.keepAddOrEdit == 'add') {
        let tagLabel = this.setNewVal(this.tagLabel).replace(/，/gi, ',')
        if (tagLabel && tagLabel.indexOf(',') != -1) {
          tagLabel.split(',').forEach((item, index) => {
            if (item !== '') {
              this.form.KeyWord.push(item);
            }
          })
        } else if (tagLabel && tagLabel.indexOf(',') == -1) {
          this.form.KeyWord.push(tagLabel);
        }

      } else {
        let tagLabel = this.setNewVal(this.tagLabel).replace(/，/gi, ',')
        if (tagLabel && tagLabel.indexOf(',') != -1) {
          tagLabel.split(',').forEach((item, index) => {
            if (item !== '') {
              this.Editform.KeyWord.push(item);
            }
          })
        } else if (tagLabel && tagLabel.indexOf(',') == -1) {
          this.Editform.KeyWord.push(tagLabel);
        }
      }
      this.tagLabel = '';
      this.tagVisible = false;
    },
    //正则
    setNewVal(valueStr) {
      const pattern =
        /[`~!@#$^&*()=|{}':;+'\\\[\]\.<>\/?~！@#￥……&*（）——|{}【】·‘’'；：""'。、？-\s]/g
      return valueStr.replace(pattern, '')
    },
    //取消
    cancel() {
      this.tagLabel = '';
      this.tagVisible = false;
    },
    //重置
    reset() {
      this.tagLabel = '';
    },
    cancelEditForm() {
      this.EditVisible = false;
    },
    submitEdit() {
      let params = {
        Id: this.Editform.Id,
        RobotId: this.$route.query.Id,
        Name: this.Editform.Name,
        Content: this.Editform.Content,
        Action: this.Editform.Action,
        KeyWord: this.Editform.KeyWord.join(',')
      }
      RobotCenter.EditGlobalList(params).then(res => {
        if (res.data.Status == 1) {
          this.$message({
            type: 'success',
            message: res.data.Message
          });
          this.GetGlobalList();
          this.EditVisible = false;
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.ba {
  background: #f0f7fd;
  color: #3388ff !important;
}

.MenuCommon_head {
  width: 100%;
  height: 60px;
  display: grid;
  align-items: center
}

.MenuCommon_body {
  width: 100%;
  height: calc(100% - 80px);
  background: #f0f7fd;
  overflow: hidden;
}

.RobotCenter_box {
  width: 100%;
  height: 100%;
}

.RobotCenter {
  width: 98%;
  //  width: 1880px;
  background: #fff;
  height: 96%;
  margin: 20px auto;
  border-radius: 10px;
  display: flex;

  .RobotCenter_left {
    width: 100px;
    //  float: left;
    padding: 24px 0px 0px 0px;
    box-sizing: border-box;

    .left_div {
      width: 60px;
      height: 200px;
      border: 1px solid #efefef;
      padding: 24px 14px 14px 14px;
      box-sizing: border-box;
      font-size: 20px;
      text-align: center;
      color: #69696a;
      margin: 0 auto;
    }

    //  background: red;
  }

  // .RobotCenter_right{
  //    width: 100%;
  //   width: 1730px;
  //   float: left;
  //   background: red;
  // }
}

.title {
  font-size: 16px;
  color: "#69696A";
  margin-left: 30px;
  margin-top: 14px;
}

.RobotCenter1 {
  width: 98%;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 10px;
  padding-left: 40px;
  padding-top: 23px;
  box-sizing: border-box;

  ::v-deep .el-tabs__header {
    margin-top: 20px;
    padding: 0 0 0 27px;
    box-sizing: border-box;
    border: 1px solid #eee;
  }

  ::v-deep .el-breadcrumb__inner {
    color: #69696a;
    font-size: 16px;
  }

  ::v-deep .el-breadcrumb__separator {
    font-weight: bold;
    color: #999;
  }

  .tabpane {
    padding-bottom: 30px;

    .tabdiv {
      ::v-deep .el-input {
        width: 240px;
        margin-right: 20px;

        .el-input__inner {
          width: 100%;
          height: 34px;
        }
      }
    }
  }
}

.demo-drawer__footer {
  margin-left: 200px;
  margin-top: 50px;
}
</style>