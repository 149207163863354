<template>
  <div>
    <div class="headassembly">
      <div class="headassembly_left">
        <h2>
          <div class="imgBox">
            <img :src="logo" alt="" v-if="logo">
            <p>{{ headerText }}</p>
          </div>

        </h2>
        <div class="headassembly_div">
          <ul>
            <li v-for="(item, index) in navList" :key="index" @click="navItem(item, index)"
                :class="{ headassemblybackground: menuindex == index + 1 }">
              <img :src="item.url" alt="">
              <p>{{ item.navName }}</p>
            </li>
          </ul>
        </div>
      </div>
      <Hander />
    </div>
    <div class="SwitchOrganization">
      <el-dialog :visible.sync="dialogVisible" width="30%">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">切换组织</span>
        </div>
        <div>
          <span style="margin-left: 40px;font-size: 16px;margin-right: 10px;">选择组织</span>
          <span style="display: inline-block;width: 350px;margin-bottom: 10px;">
            <el-select v-model="OrganizationId" placeholder="请选择" value-key="Id">
              <el-option v-for="(item, index) in organization" :key="index" :label="item.Name" :value="item">
              </el-option>
            </el-select>
          </span>
        </div>
        <div class="btn">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="determine">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <div>
      <el-dialog :visible.sync="VdialogVisible" fullscreen width="100%">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">任务列表</span>
        </div>
        <div class="loading_table_head" style="margin-bottom: 20px;">
          <span style="display: inline-block;width: 300px;margin-right: 10px;"><el-input v-model="input"
                                                                                         placeholder="请输入内容"></el-input></span>
          <el-button type="primary" icon="el-icon-search">搜索</el-button>
        </div>
        <div style="margin-bottom:10px;">
          <el-table border :data="VtableData" :header-cell-style="{ background: 'rgb(240, 247, 253)' }"
                    style="width: 100%;height:600px;margin-bottom: 20px;">
            <el-table-column align="center" prop="ProjectName" label="项目名称" width="280">
            </el-table-column>
            <el-table-column align="center" prop="QuestionnaireName" label="问卷名称" width="280">
            </el-table-column>
            <el-table-column align="center" prop="TargetName" label="患者姓名" width="100">
            </el-table-column>
            <el-table-column align="center" prop="TargetTel" label="患者手机" width="140">
            </el-table-column>
            <el-table-column align="center" prop="TriggerTime" label="随访日期" width="200">
            </el-table-column>
            <el-table-column align="center" prop="UserName" label="主管医生" width="200">
            </el-table-column>
            <el-table-column align="center" prop="UserTel" label="主管电话" width="200">
            </el-table-column>
            <el-table-column align="center" prop="ChannelName" label="随访方式">
            </el-table-column>
            <el-table-column align="center" prop="StatusName" label="状态">
            </el-table-column>
            <el-table-column prop="Frq" label="访视次数" align="center">
            </el-table-column>
            <el-table-column align="center" fixed="right" label="操作" width="120">
              <template slot-scope="scope">
                <el-link @click="handleClick(scope.row)" type="text" :underline="false" style="margin-right: 11px"><i
                    class="el-icon-search"></i>填写问卷</el-link>
              </template>
            </el-table-column>
          </el-table>
          <div>
            <Pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.rows"
                        @pagination="MyMissionDetailed">
            </Pagination>
          </div>
        </div>
        <div class="btn">
          <el-button @click="VdialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="VdialogVisible = false">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="QuestionnaireFilling">
      <el-dialog :visible.sync="QuestionnaireFillingDialog" fullscreen>
        <iframe :src="Url" frameborder="0" width="100%" height="1080px" ref="myFrame"></iframe>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { session, local } from "@/utils/storage";
import { mapState } from "vuex";
import administrators from "../api/administrators"
import login from "../api/login"
import Pagination from "../components/Pagination/index.vue"
import Hander from "./Hander.vue";
export default {
  data() {
    return {
      total: 0,
      Channel: null,
      input: "",
      Url: "",
      PwdPlainText: 'sq1123123',
      QuestionnaireFillingDialog: false,
      VtableData: [],
      listQuery: {
        Key: "",
        page: 1,
        rows: 10,
      },
      VdialogVisible: false,
      dialogVisible: false,
      OrganizationName: local.get("OrganizationName"),
      navList: [
        {
          path: "/HomePage",
          navName: "项目管理",
          url: require('../assets/image/icon/project-management1.png'),
        },
        {
          path: "/questionnaire",
          navName: "问卷中心",
          url: require('../assets/image/icon/questionnaire-enter1.png'),
        },
        {
          path: "/patentList",
          navName: "患者中心",
          url: require('../assets/image/icon/a222.png'),
        },
        {
          path: "/DataCenter",
          navName: "已收集问卷",
          url: require('../assets/image/icon/patient-list1.png'),
        },
        {
          path: "/derive",
          navName: "查询导出",
          url: require('../assets/image/icon/query-export.png'),
        },
        {
          path: "/DataAnalysis",
          navName: "数据分析",
          url: require('../assets/image/icon/a111.png'),
        },
        {
          path: "/statistics",
          navName: "数据统计",
          url: require('../assets/image/icon/data-statistics1.png'),
        },
        {
          path: "/task",
          navName: "随访任务",
          url: require('../assets/image/icon/follow-up-task.png'),
        },
        {
          path: "/report",
          navName: "工作汇报",
          url: require('../assets/image/icon/work-report.png'),
        },
        {
          path: "/RobotCenter",
          navName: "机器人中心",
          url: require('../assets/image/icon/a333.png'),
        },
      ],
      //是否显示设置信息
      message: false,
      organization: [],
      OrganizationId: {
        Id: ''
      },
      headerText: local.get('headerText') || '智能随访机器人大数据平台',
      logo: ''
    }
  },
  computed: mapState(["menuindex"]),
  created() {
    this.GetUserMy();
    if (session.get("menuindex")) {
      this.$store.commit("menustate", session.get("menuindex"));
    } else {
      this.$store.commit("menustate", 1);
    }
    this.logo = local.get('logo')
  },
  components: {
    Pagination, Hander
  },
  methods: {
    //获取个人信息
    async GetUserMy() {
      const { data } = await administrators.GetUserMy();
      if (data.Status == 1) {
        console.log(this.PwdPlainText, data.Data);
        // this.PwdPlainText = data.Data.BasePart.PwdPlainText;
      }
    },
    handleClick(item) {
      this.Url = ""
      // const parameter={
      //     ProjectId:item.ProjectId
      // }
      // questionnaire.CallBackQuestionnaireData(parameter).then(res=>{
      //              console.log(res);
      //             if(res.data.Status==1){
      //                   let sysData=JSON.parse(res.data.Data)
      //                   sysData['FollowUpTaskId']=item.Id
      //                   sysData['FillType']='D'

      //                   this.QuestionnaireFillingDialog=true
      //                   this.Url=`${window.global_config.BASE_URL1}/#/P?sysData=${JSON.stringify(sysData)}&type=1&qid=${item.ProjectQuestionnaireId}&userId=${item.TargetId}&userName=${item.UserName}&fillinId=${local.get('UserId')}&fillinName=${local.get('UserName')}&phone=${item.TargetTel}&visitsNumber=${item.Frq}`
      //             }else{
      //                 alert(res.data.Message+"++++++")
      //             }
      // })
      if (this.VtableData && this.VtableData[0].CallBackInfo.IsSuccess) {
        this.QuestionnaireFillingDialog = true;
        this.Url = `${window.global_config.BASE_URL1}/#/P?sysData=${this.VtableData[0].CallBackInfo.CallBackParameterEncrypt}&type=1&qid=${item.ProjectQuestionnaireId}&userId=${item.TargetId}&userName=${item.UserName}&fillinId=${local.get('UserId')}&fillinName=${local.get('UserName')}&phone=${item.TargetTel}&visitsNumber=${item.Frq}`;
        console.log(this.Url)
      };
    },
    ViewTasks(item) {
      this.VdialogVisible = true
      this.Channel = item.Channel
      this.MyMissionDetailed()
    },
    MyMissionDetailed() {
      const parameter = {
        Key: this.listQuery.Key,
        page: this.listQuery.page,
        rows: this.listQuery.rows,
        Channel: this.Channel
      }
      login.MyMissionDetailedData(parameter).then(res => {
        console.log(res, "我的任务明细");
        if (res.data.Status == 1) {
          this.total = res.data.Data.total
          this.VtableData = res.data.Data.rows
        } else {
          alert(res.data.Message)
        }
      })
    },
    determine() {
      console.log(this.OrganizationId);
      local.set("OrganizationName", this.OrganizationId.Name)
      local.set("currentOrganizationId", this.OrganizationId.Id)
      // local.set("UserName",this.OrganizationId.Name)
      this.dialogVisible = false
      this.$store.commit("menustate", 1);
      this.$router.push("/EmptyPage");
    },
    //组织
    NoPagingOrganization() {
      const parameter = {
        Key: "",
      }
      administrators.NoPagingOrganizationData(parameter).then(res => {
        console.log(res, "组织");
        if (res.data.Status == 1) {
          this.organization = res.data.Data
        } else {
          alert(res.data.Message)
        }
      })
    },
    //切换组织
    SwitchOrganization() {
      this.OrganizationId.Id = local.get("currentOrganizationId")
      this.NoPagingOrganization()
      this.dialogVisible = true
    },

    Switchingsystem() {

    },
    changeFontSize(size) {
      window.document.documentElement.setAttribute("data-size", size);
    },
    navItem(item, index) {
      // console.log(item.path)
      local.set('IsRtc', true)
      this.$store.commit("menustate", index + 1);
      this.$router.push(item.path)
    },
  }
}
</script>
<style lang="scss">
@import "../styles/mixin.scss";

.QuestionnaireFilling {
  ::v-deep .el-dialog__headerbtn {
    top: 3px;
    font-size: 24px;
  }
}

.SwitchOrganization {
  ::v-deep .el-input__inner {
    width: 350px;
  }
}

.headassembly {
  width: 100%;
  height: 70px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .headassembly_left {
    //width: 1420px;
    height: 100%;
    float: left;
    box-shadow: 0 3px 0 rgba(0, 104, 163, 0.1);
    display: flex;

    h2 {
      //width: 400px;
      height: 100%;
      float: left;
      display: flex;
      padding-left: 35px;
      box-sizing: border-box;

      img {
        // margin-left: 22px;
        // margin-top: 12px;
        // margin-right: -10px;
        vertical-align: middle;
      }

      p {
        display: table-cell;
        vertical-align: middle;
        font-size: 26px;
        color: #333333;
        line-height: 40px;
      }

      .imgBox {
        display: flex;
        align-items: center;
        gap: 10px;

        img {
          width: 50px;
        }
      }
    }

    .headassembly_div {
      width: 1010px;
      height: 100%;
      float: left;

      ul {
        width: 1010px;
        height: 100%;
        overflow: hidden;

        li {
          width: 84px;
          height: 80%;
          text-align: center;
          float: left;
          border-radius: 4px;
          cursor: pointer;
          margin-top: 6px;
          margin-left: 10px;

          img {
            width: 24px;
            height: 24px;
            margin-top: 6px;
          }

          p {
            font-size: 16px;
            color: #69696A;
          }
        }
      }
    }
  }

  // .headassembly_right {
  //   width: 500px;
  //   height: 100%;
  //   float: left;
  //   display: flex;
  //   justify-content: space-between;
  //   padding-top: 23px;
  //   box-shadow: 0 3px 0 rgba(0, 104, 163, 0.1);
  //   box-sizing: border-box;

  //   // .right_margin {
  //   //   margin-left: 100px;
  //   // }
  //   ::v-deep .el-dropdown-menu {
  //     .el-dropdown-menu__item {
  //       padding: 0 10px;
  //     }
  //   }
  // }
}

.headassemblybackground {
  background: linear-gradient(180deg, #5F7FFE 0%, #A5BDFF 100%);

  p {
    color: #ffffff !important;
  }
}

.edit-password {
  ::v-deep .el-dialog {
    width: 578px;

    .el-form {
      .el-form-item {
        .el-form-item__label {
          @include add-size($font_size_16);
          color: #666666;
        }
      }
    }
  }
}

.dialog_add {
  ::v-deep .el-dialog {
    width: 1200px;
  }
}

.radio-dian {
  width: 7px;
  height: 7px;
  background: #F94747;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  margin-top: 1px;
  opacity: 1;
}

.content {
  ::-webkit-scrollbar {
    width: 8px !important;
    height: 8px;

  }

  ::-webkit-scrollbar-thumb {
    background-color: #9AC2EF;
    border-radius: 10px;
  }
}

.block {
  margin-top: 10px;
}

.notice-details ::v-deep .el-input {
  @include add-size($font_size_16);
  border: 0;
}

.notice-details ::v-deep .el-textarea {
  @include add-size($font_size_16);
  border: 0;
}

@media screen and (min-width: 1200px) and (max-width: 1560px) {
  .headassembly {
    width: 1440px;

    .headassembly_left {
      width: 940px;

      h2 {
        width: 340px;
        padding-left: 10px;
      }

      .headassembly_div {
        width: 600px;

        ul {
          width: 600px;
          height: 100%;
          overflow: hidden;

          li {
            width: 64px;
          }
        }
      }
    }

    .headassembly_right {
      width: 500px;

      .right_margin {
        margin-left: 0;
      }

      .system_right {
        margin-right: 0;
      }

      .UserName {
        margin-right: 0px !important;
      }
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5) {
  .headassembly {
    width: 100%;

    .el-dropdown-link {
      align-content: center !important;

      .zhuren {
        font-size: 14px !important;
      }
    }

    li {

      margin-left: 0 !important;

      p {
        font-size: 14px !important;
      }
    }

    .headassembly_left {
      width: 83%;

      .headassembly_div {
        width: 100%;
      }

      ul {
        display: flex;
        width: 100% !important;

        li {
          flex: 1;
        }

        li:nth-child(9) {
          display: none;
        }
      }
    }

    .headassembly_right {
      // width: 15%;
      flex: 1;

      .system_right {
        display: none;
      }

      .el-badge {
        // display: none;
      }

      img {
        width: 25px;
        height: 25px;
      }

      // :nth-child(2){
      //   display: none;
      // }
      .padClass {
        // display: none;
      }

      // :nth-child(5){
      //   display: none;
      // }
      .UserName {
        margin: 0 !important;
      }
    }

    h2 {
      display: none !important;
    }
  }
}
</style>
