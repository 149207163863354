// 数据统计api
import axios from "./Interceptor.js"
let address =window.global_config.BASE_URL2
let Statistics = {
    // 系统患者数
    StatisticsPart1Data: (info) => {
        return axios({
            method: "post", 
            url: `${address}Report/StatisticsPart1`,
            data:info
        })
    },
    // 全部AI有效随访人群中不同年,最近一月内AI有效随访人群中不同年龄  
    StatisticsPart2Data: (info) => {
            return axios({
                method: "post", 
                url: `${address}Report/StatisticsPart2`,
                data:info
            })
   },
    //追踪通知, 消息通知  
    StatisticsPart3Data: (info) => {
        return axios({
            method: "post", 
            url: `${address}Report/StatisticsPart3`,
            data:info
        })
    },
    //这是无分页的消息列表 
    NewsListData: () => {
            return axios({
                method: "post", 
                url: `${address}FollowUp/Message/GetList`,
            })
    },
    //数据分析 
    RReportData: (info) => {
            return axios({
                method: "post", 
                url: `${address}Report/RReport`,
                data:info
            })
    },
}
export default Statistics