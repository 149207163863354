<template>
  <div>
    <div class="container">
      <h2 v-if="$store.state.menuFlag" style="margin-left: 20px;">
        <div class="imgBox">
          <img :src="logo" alt="" v-if="logo">
          <p>{{ headerText }}</p>
        </div>
      </h2>
      <div class="headassembly_right">
        <!-- <div style="" class="right_margin">
        <el-dropdown>
                <span class="el-dropdown-link" style="font-size: 16px">
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>c
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item  @click.native="changeFontSize('0')">小</el-dropdown-item>
            <el-dropdown-item  @click.native="changeFontSize('1')">中</el-dropdown-item>
            <el-dropdown-item  @click.native="changeFontSize('2')">大</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div> -->
        <!--        <div class="system_right">-->
        <!--          <el-button type="info" plain style="width: 128px;height: 36px;border-radius: 138px;position: relative"-->
        <!--            @click="Switchingsystem" disabled>-->
        <!--            <span style="bottom: 8px;position: absolute;right: 20px;font-size: 16px;color: #909399">科研系统</span>-->
        <!--          </el-button>-->
        <!--        </div>-->
        <div @click="news" style="display: flex;cursor:pointer;">
          <el-badge :value="newsList.length" class="item">
            <img src="../assets/image/notice/lingdang.png" alt="" style="width: 18px;height: 18px;">
          </el-badge>
        </div>
        <div class="padClass" style="cursor:pointer;" @click="SystemSettings"
             v-if="admin == '00000000-0000-0000-0000-000000000000' || RoleType == 'D'">
          <img src="../assets/image/notice/setting.png" alt="" style="width: 18px;height: 18px">
        </div>
        <el-tooltip class="item padClass" effect="dark" :content="UnitName" placement="top">
          <div style=""><span>{{ UnitName | ellipsis }}</span></div>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" :content="UserName + TitleName" placement="top">
          <div style="margin-right: 40px;margin-top: 5px" class="UserName">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link" style="display: flex;">
                <img :src="Sex == '1' ? require('../assets/image/30nan.png') : require('../assets/image/30nv.png')"
                     alt="" style="margin-right: 5px">
                <div style="display: flex; align-items: center;">
                  <span style="font-size: 16px;" class="zhuren">{{
                      UserName + TitleName | ellipsis
                    }}</span>
                  <i class="el-icon-caret-bottom"></i>
                </div>
              </span>
              <el-dropdown-menu slot="dropdown">
                <!-- <el-dropdown-item>个人中心</el-dropdown-item> -->
                <el-dropdown-item @click.native="SwitchOrganization"
                                  v-if="admin == '00000000-0000-0000-0000-000000000000'">切换组织
                </el-dropdown-item>
                <el-dropdown-item @click.native="editPasswordDialog = true">修改密码</el-dropdown-item>
                <el-dropdown-item @click.native="LogOut">退出登录</el-dropdown-item>
                <el-dropdown-item>
                  <el-switch style="display: block;" v-model="$store.state.menuFlag" active-color="#13ce66"
                             inactive-color="#ff4949" active-text="左右布局" inactive-text="上下布局">
                  </el-switch>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-tooltip>
      </div>
    </div>
    <div class="news">
      <el-dialog :visible.sync="NewsdialogVisible" width="50%">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">消息列表</span>
        </div>
        <el-tabs v-model="activeName">
          <el-tab-pane label="消息列表" name="first">
            <el-table :data="newsList" border :header-cell-style="{ background: 'rgb(240, 247, 253)' }"
                      style="width: 100%;margin-bottom: 10px;">
              <el-table-column align="center" prop="TriggerTime" label="发送日期" width="180">
              </el-table-column>
              <el-table-column align="center" prop="UserName" label="参研人员" width="180">
              </el-table-column>
              <el-table-column align="center" prop="MsgContent" label="消息内容">
              </el-table-column>
              <el-table-column align="center" prop="address" label="是否处理">
                <template slot-scope="scope">
                  <p>{{ scope.row.IsHandled == true ? '已处理' : '未处理' }}</p>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="待办任务" name="second">
            <el-table :data="ToDoTasks" :header-cell-style="{ background: 'rgb(240, 247, 253)' }" border
                      style="width: 100%">
              <el-table-column align="center" prop="ChannelName" label="任务方式">
              </el-table-column>
              <el-table-column align="center" prop="Count" label="数量">
              </el-table-column>
              <el-table-column align="center" label="操作" width="180">
                <template slot-scope="scope">
                  <el-link :underline="false" @click="ViewTasks(scope.row)"><i
                      class="el-icon-edit-outline"></i>查看
                  </el-link>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <!-- <div  class="btn">
          <el-button @click="NewsdialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="NewsdialogVisible = false">确 定</el-button>
        </div> -->
      </el-dialog>
    </div>
    <div class="edit-password">
      <el-dialog top="25vh" :visible.sync="editPasswordDialog">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">修改密码</span>
        </div>
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="原始密码" prop="pwd">
            <el-input type="password" v-model="ruleForm.pwd" autocomplete="off" show-password
                      placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="pass">
            <el-input type="password" v-model="ruleForm.pass" autocomplete="off" show-password
                      placeholder="请输入6到20位之间的密码"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPass">
            <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off" show-password
                      placeholder="请输入6到20位之间的密码"></el-input>
          </el-form-item>
          <el-form-item>
            <div class="btn">
              <button type='button' @click="cancelEditPswDialog">取消</button>
              <button type='button' @click="submitForm">确定</button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {local, session} from "@/utils/storage";
import Statistics from "../api/Statistics"
import login from "../api/login"
import administrators from "@/api/administrators";

export default {
  name: 'Handler',
  data() {
    var validatePwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      newsList: [],
      admin: local.get('UserId'),
      RoleType: local.get('RoleType'),
      UnitName: local.get('UnitName') || '',
      UserName: local.get('UserName') || '',
      TitleName: local.get('TitleName') !== 'undefined' ? local.get('TitleName') : '',
      Sex: local.get('Sex'),
      activeName: "first",
      ToDoTasks: [],
      NewsdialogVisible: false,
      headerText: local.get('headerText') || '智能随访机器人大数据平台',
      ruleForm: {
        pwd: '',
        pass: '',
        checkPass: '',
      },
      rules: {
        pwd: [
          {validator: validatePwd, trigger: 'blur'},
          {min: 1, max: 20, message: '密码不能为空'}
        ],
        pass: [
          {validator: validatePass, trigger: 'blur'},
          {min: 6, max: 20, message: '密码在6到20位之间'}
        ],
        checkPass: [
          {validator: validatePass2, trigger: 'blur'},
          {min: 6, max: 20, message: '密码在6到20位之间'}
        ]
      },
      editPasswordDialog: false,
      logo: ''
    }
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 5) {
        return value.slice(0, 5) + "...";
      }
      return value;
    },
  },
  created() {
    this.logo = local.get('logo')
  },
  methods: {
    Switchingsystem() {

    },
    //提交密码
    submitForm() {
      this.$refs.ruleForm.validate(async (valid) => {
        console.log(valid, 'valid')
        if (valid) {
          await this.KeepUserMy();
          this.editPasswordDialog = false;
        } else {
          return false;
        }
      });
    },
    //修改个人信息
    async KeepUserMy() {
      let parameter = {
        OldPwd: this.ruleForm.pwd,
        NewPwd: this.ruleForm.pass,
        ConfirmPwd: this.ruleForm.checkPass
      };
      console.log(parameter, 'parameter')
      const {data} = await administrators.KeepUserMy(parameter);
      if (data.Status == 1) {
        this.$message({
          message: data.Message,
          type: "success",
        });
        this.LogOut();
      }
    },
    //取消
    cancelEditPswDialog() {
      this.$refs.ruleForm.resetFields();
      this.editPasswordDialog = false;
    },
    news() {
      this.NewsdialogVisible = true
      Statistics.NewsListData().then(res => {
        console.log(res, '消息');
        if (res.data.Status == 1) {
          this.newsList = res.data.Data
        } else {
          alert(res.data.message)
        }
      })
      this.MyMission()
    },
    //系统设置
    SystemSettings() {
      this.$router.push({name: "navber"});
    },
    //我的任务
    MyMission() {
      login.MyMissionData().then(res => {
        console.log(res, "我的任务");
        if (res.data.Status == 1) {
          this.ToDoTasks = res.data.Data
        } else {
          alert(res.data.Message)
        }
      })
    },
    //退出登录
    LogOut() {
      this.$router.push('/');
      session.clear();
      local.clear();
      location.reload();
    },
  },
}
</script>

<style lang="scss" scoped>
// @import "../styles/mixin.scss";

.container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headassembly_right {
  // width: 15.625vw;
  height: 100%;
  // float: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  // padding-top: 23px;
  // box-shadow: 0 3px 0 rgba(0, 104, 163, 0.1);
  box-sizing: border-box;

  // .right_margin {
  //   margin-left: 100px;
  // }
  ::v-deep .el-dropdown-menu {
    .el-dropdown-menu__item {
      padding: 0 10px;
    }
  }
}

.news {
  ::v-deep .el-dialog__body {
    padding: 0px 20px 20px;
  }
}

.imgBox {
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 65px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1560px) {
  .headassembly {
    width: 1440px;

    .headassembly_right {
      width: 500px;

      .right_margin {
        margin-left: 0;
      }

      .system_right {
        margin-right: 0;
      }

      .UserName {
        margin-right: 0px !important;
      }
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5) {
  .headassembly {
    // width: 100%;
    display: flex;

    .el-dropdown-link {
      align-content: center !important;

      .zhuren {
        font-size: 14px !important;
      }
    }

    li {

      margin-left: 0 !important;

      p {
        font-size: 14px !important;
      }
    }

    .headassembly_right {
      // width: 15%;
      flex: 1;

      .system_right {
        display: none;
      }

      .el-badge {
        // display: none;
      }

      img {
        width: 25px;
        height: 25px;
      }

      // :nth-child(2){
      //   display: none;
      // }
      .padClass {
        // display: none;
      }

      // :nth-child(5){
      //   display: none;
      // }
      .UserName {
        margin: 0 !important;
      }
    }

    h2 {
      display: none !important;
    }
  }
}
</style>
